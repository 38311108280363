import { createAction } from '@reduxjs/toolkit';
import { globalSearchSlice } from './reducer';

export const fetchGlobalItems = createAction('globalSearch/fetchGlobalItems');
export const restartGlobalSearch = createAction('globalSearch/restartGlobalSearch');

export const {
  setIsHeaderSearchActive,
  addNewRecentSearch,
  removeRecentSearch,
  loadRecentSearches,
  setSearchExpanded,
} = globalSearchSlice.actions;
