import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BrowserFirefoxIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16509 3.921C5.36009 1.519 8.50809 0 12.0121 0C18.6291 0 24.0121 5.383 24.0121 12C24.0121 18.617 18.6291 24 12.0121 24C5.39508 24 0.012085 18.617 0.012085 12C0.012085 10.104 0.466085 8.317 1.25208 6.721C1.26208 6.663 1.26708 6.623 1.26708 6.623C0.811085 3.545 2.51109 2.277 2.51109 2.277C2.51109 2.277 2.64609 3.178 3.16509 3.921ZM8.56059 15.99C14.7116 20.482 20.7656 14.329 20.3156 6.439C18.5186 3.765 15.4666 2 12.0096 2C9.49858 2 7.20758 2.937 5.44958 4.471C5.88558 4.461 6.34359 4.483 6.72859 4.579C6.72959 4.577 7.91658 2.936 9.97359 3.236C8.77559 4.224 8.35359 4.978 8.30958 5.583C8.27458 6.068 8.79858 6.555 9.57959 6.548C9.98958 6.545 11.0986 6.549 11.5646 6.66C11.8456 6.763 11.7486 7.256 11.6626 7.466C11.408 8.08741 9.90493 9.21204 8.8567 9.99635C8.41037 10.3303 8.0465 10.6026 7.89658 10.748C7.79358 10.768 7.54858 11.07 7.48458 11.299C7.14158 12.515 9.11858 14.591 12.0826 13.187C13.1626 12.675 14.0926 13.438 14.4436 13.842C14.7496 14.193 14.6066 14.682 13.9986 14.592C13.7433 14.5505 13.3018 14.8365 12.8542 15.1265C12.6441 15.2626 12.4327 15.3996 12.2386 15.504C11.2746 16.022 8.57059 15.992 8.56059 15.99Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BrowserFirefoxIcon;
