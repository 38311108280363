import getLanguage from 'functions/getLanguage';
import isTablet from 'functions/isTablet';
import { getReferralLinkParams } from 'functions/redirectLink';
import { oldApiInstance } from 'functions/request';
import guestSignUpHandler from 'functions/authentication/guestSignUpHandler';
import getRecaptchaToken from 'functions/getRecaptchaToken';
import { authenticateUser } from './authentication';
import guestConvertHandler from './guestConvertHandler';
import signUpHandler from './signUpHandler';
import { handleErrorFromServer } from './handleErrorFromServer';

const convertFromGuest = data => oldApiInstance.put('/user/convert_from_guest', data).catch(handleErrorFromServer);
const signUpUser = data => {
  return oldApiInstance.post('/sign_up', data).catch(handleErrorFromServer);
};

export const guestSignUpUser = async ({ email, password }) => {
  // TODO: we don't set isGuest anywhere, probably this logic can be removed
  const isGuest = localStorage.getItem('isGuest');

  if (isGuest) {
    const guestUserData = {
      name: '',
      gender: '',
      email,
      password,
      language: getLanguage(),
    };

    return convertFromGuest(guestUserData).then(async ({ data }) => {
      authenticateUser({ is_converted_from_guest: true, ...data });
      await guestConvertHandler(data);

      return { isGuest: true, data };
    });
  }

  const referral_link_params = getReferralLinkParams();

  const userData = {
    name: '',
    gender: '',
    email,
    password,
    password_confirmation: password,
    signed_up_from_tablet: isTablet(),
    xuid: localStorage.getItem('xuid'),
    language: getLanguage(),
    ...referral_link_params,
  };

  return signUpUser(userData).then(async ({ data }) => {
    await signUpHandler(data);
    authenticateUser(data);

    return { isGuest: false, data };
  });
};

export const skipGuestSignUp = async () => {
  const userData = getReferralLinkParams();
  const recaptchaToken = await getRecaptchaToken('SIGNUP');
  const { data } = await oldApiInstance.post('/guest_sign_up', {
    ...userData,
    'g-recaptcha-response': recaptchaToken,
    language: getLanguage(),
  });

  authenticateUser(data);
  await guestSignUpHandler(data);

  return data;
};
