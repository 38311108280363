import { call, put } from 'redux-saga/effects';
import { fetchPromoStoreCouponsApi } from '../api';
import { setIsFetching, setPromoStoreCoupons, clearPromoStoreCoupons } from '../actions';

const PER_PAGE = 20;

function* fetchPromoStoreCouponsWorker({ payload }) {
  const { ruleId, page } = payload;

  try {
    if (page === 1) {
      yield put(clearPromoStoreCoupons());
    } else {
      yield put(setIsFetching(true));
    }

    const response = yield call(fetchPromoStoreCouponsApi, { ruleId, page, perPage: PER_PAGE });

    yield put(
      setPromoStoreCoupons({
        items: response.data.offers || [],
        page,
        isLastPage: response.data.offers.length < PER_PAGE,
      }),
    );
  } catch (error) {
    yield put(setPromoStoreCoupons({ items: [], page, isLastPage: true }));
    console.error(error);
  }
}

export default fetchPromoStoreCouponsWorker;
