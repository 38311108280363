import { call, put } from 'redux-saga/effects';
import { fetchPromoMixItemsApi } from '../api';
import { clearPromoMixItems, setPromoMixItems, setIsFetching } from '../actions';

const PER_PAGE = 20;

function* fetchPromoMixItemsWorker({ payload }) {
  const { page } = payload;

  try {
    if (page === 1) {
      yield put(clearPromoMixItems());
    } else {
      yield put(setIsFetching(true));
    }

    const response = yield call(fetchPromoMixItemsApi, { page, perPage: PER_PAGE });

    yield put(
      setPromoMixItems({
        items: response.data.offers || [],
        page,
        isLastPage: response.data.offers.length < PER_PAGE,
      }),
    );
  } catch (error) {
    yield put(setPromoMixItems({ items: [], page, isLastPage: true }));
    console.error(error);
  }
}

export default fetchPromoMixItemsWorker;
