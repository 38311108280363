import { call, put } from 'redux-saga/effects';
import { fetchPromoStoreTagsApi } from '../api';
import { setPromoStoreTags, setIsFetching, clearPromoStoreTags } from '../actions';

const PER_PAGE = 20;

function* fetchPromoStoreTagsWorker({ payload }) {
  const { ruleId, page } = payload;

  try {
    if (page === 1) {
      yield put(clearPromoStoreTags());
    } else {
      yield put(setIsFetching(true));
    }

    const response = yield call(fetchPromoStoreTagsApi, { ruleId, page, perPage: PER_PAGE });

    yield put(
      setPromoStoreTags({
        items: response.data.items || [],
        page,
        isLastPage: response.data.items.length < PER_PAGE,
      }),
    );
  } catch (error) {
    yield put(setPromoStoreTags({ items: [], page, isLastPage: true }));
    console.error(error);
  }
}

export default fetchPromoStoreTagsWorker;
