import SOURCE_PAGES from './sourcePages';
import MODAL_NAMES from './modalNames';
import MODAL_TYPES from './modalTypes';
import SECTIONS from './sections';
import ELEMENTS from './elements';

export { SOURCE_PAGES, ELEMENTS, MODAL_NAMES, MODAL_TYPES, SECTIONS };

// * version
export const VERSION = process.env.REACT_APP_VERSION || '10.27.0';

export const ORIGIN = {
  DESKTOP: 'desktop_web',
  MOBILE: 'mobile_web',
  TABLET: 'tablet_web',
};

export const ATTRS = {
  COUPON_ID: 'data-alooma-coupon-id',
  DISABLED: 'data-alooma-disabled',
  ELEMENT: 'data-alooma-element',
  RETAILER_ID: 'data-alooma-retailer-id',
  NOTIFICATION_ID: 'data-alooma-notification-id',
  RULE_ID: 'data-alooma-rule-id',
  PACKAGE_ID: 'data-alooma-package-id',
  SECTION: 'data-alooma-section',
  SOURCE: 'data-alooma-source',
  TEXT: 'data-alooma-text',
  LIST_ID: 'data-alooma-list-id',
  ABSOLUTE_POSITION: 'data-alooma-absolute-position',
  RELATIVE_POSITION: 'data-alooma-relative-position',
  PRIORITY: 'data-alooma-priority',
  DESTINATION_URL: 'data-alooma-destination-url',
  MODAL_TYPE: 'data-alooma-modal',
  MODAL_NAME: 'data-alooma-modal-name',
  NON_STANDARD: 'data-alooma-non-standard',
  TAG_ID: 'data-alooma-item-id',
  STEP: 'data-alooma-step',
  USER_ID: 'data-alooma-user-id',
};

export const EVENT_NAMES = {
  PAGE_VIEW: 'page_view',
  CLICK: 'click',
  IMPRESSION: 'impression',

  STANDARD_REG: 'standart_reg',
  GUEST_REG: 'guest_reg',
  COMPLETE_REG: 'complete_registration',
  STARTED_SIGNUP_TUTORIAL: 'Started Signup Tutorial',
};

export const PARAMS = {
  DESTINATION_URL: 'destination_url',
  PRIORITY: 'priority',
  ABSOLUTE_POSITION: 'absolute_position',
  RELATIVE_POSITION: 'relative_position',
  TEXT: 'text',
  LIST_ID: 'list_id',
  ELEMENT: 'element_name',
  TAG_ID: 'tag_id',
  RETAILER_ID: 'retailer_id',
  NOTIFICATION_ID: 'notification_id',
  RULE_ID: 'rule_id',
  COUPON_ID: 'coupon_id',
  PACKAGE_ID: 'package_id',
  SECTION: 'page_section',
  SOURCE: 'source_page',
  MODAL_TYPE: 'modal',
  MODAL_NAME: 'modal_name',
  FUNNEL_STEP: 'funnel_step',
  FUNNEL_NAME: 'funnel_name',
  FUNNEL_STEP_OPTIONAL: 'funnel_step_optional',
  ORIGIN: 'source',
  URL: 'browser_url',
  USER_ID: 'user_id',
  VERSION: 'site_version',
  GUEST_ACCESS_DISABLED: 'guest_access_disabled',
  STEP: 'step',
  GA_CLIENT_ID: 'ga4_client_id',
  LP_TEST: 'lp_test',
  SEARCH_PHRASE: 'search_phrase',
  SEARCH_RESULTS: 'search_results',
  ERROR_MESSAGE: 'error_message',
  BROWSER: 'browser',
  EVENT_NAME: 'event',
  ALOOMA_TOKEN: 'token',
  TIMESTAMP: 'timestamp',
  DISTINCT_ID: 'distinct_id',
  IS_APP_LOG: 'is_app_log',
};
