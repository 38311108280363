import { useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { CollapseArrowIcon, SelectArrowIcon } from '@karma/components/icons';
import * as ALOOMA from 'consts/alooma';
import { DEFAULT_TAG } from 'consts/defaultImages';
import Alooma from 'services/alooma';
import { ENTITIES } from 'consts/entities';
import SaveTagButton from 'components/atoms/SaveTagButton/SaveTagButton';
import ShareTagButton from 'components/atoms/ShareTagButton/ShareTagButton';
import { useIsSavedItem } from 'hooks/useGlobalItemHooks';
import hasTagMarker from 'functions/hasTagMarker';
import { settings } from './settings';
import {
  Column,
  BadgeWrapper,
  StyledTagImage,
  Link,
  IconWrapper,
  ButtonsContainer,
} from './ModalProductImagesSection.style';

const ModalProductImagesSection = ({ redirectToTagLink, tag, closeModal }) => {
  const { marker, main_img_url, name, item_images, retailer_logo: retailerLogo } = tag;
  const isSavedItem = useIsSavedItem(tag);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const itemImages = useMemo(
    () =>
      item_images?.reduce((result, image) => {
        if (!image || image.endsWith('.php')) return result;

        return [...result, { key: uniqueId(), image }];
      }, []) || [],
    [item_images],
  );

  return (
    <Column>
      {itemImages.length > 1 ? (
        <Swiper
          {...settings}
          renderNextButton={() => (
            <IconWrapper role="button" className="swiper-button-next">
              <SelectArrowIcon size="medium" color="primary1" />
            </IconWrapper>
          )}
          renderPrevButton={() => (
            <IconWrapper role="button" className="swiper-button-prev">
              <CollapseArrowIcon size="medium" color="primary1" />
            </IconWrapper>
          )}
        >
          {itemImages.map(({ image, key }) => (
            <div key={key}>
              <Link
                target="_blank"
                href={redirectToTagLink}
                {...contextDataAttrs}
                data-alooma-element={ALOOMA.ELEMENTS.MAIN_IMAGE}
              >
                <StyledTagImage
                  alt={image}
                  mainSrc={image || retailerLogo || DEFAULT_TAG}
                  secondarySrc={main_img_url}
                  backupSrc={retailerLogo}
                />
              </Link>
            </div>
          ))}
        </Swiper>
      ) : (
        <Link
          target="_blank"
          href={redirectToTagLink}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.MAIN_IMAGE}
        >
          <StyledTagImage
            alt={name}
            mainSrc={main_img_url || retailerLogo || DEFAULT_TAG}
            backupSrc={retailerLogo}
            rounded
          />
        </Link>
      )}
      <ButtonsContainer direction="column" spacing={14}>
        <SaveTagButton tag={tag} closeModal={closeModal} />
        {isSavedItem && <ShareTagButton tag={tag} />}
      </ButtonsContainer>
      {hasTagMarker(tag) && <BadgeWrapper marker={marker} />}
    </Column>
  );
};

ModalProductImagesSection.propTypes = {
  redirectToTagLink: PropTypes.string.isRequired,
  tag: ENTITIES.tag.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalProductImagesSection;
