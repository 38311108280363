import alooma from 'services/alooma';
import trackFacebookEvent from 'functions/trackFacebookEvent';
import trackGoogleTagEvent from 'functions/trackGoogleTagEvent';
import * as ALOOMA from 'consts/alooma';

const guestSignUpHandler = async data => {
  trackFacebookEvent('CompleteRegistration');

  await Promise.all([
    alooma.UNSAFE_trackAloomaEvent(ALOOMA.EVENT_NAMES.GUEST_REG, alooma.formRegistrationData(data)),
    alooma.UNSAFE_trackAloomaEvent(ALOOMA.EVENT_NAMES.COMPLETE_REG, {
      [ALOOMA.PARAMS.USER_ID]: data.user_id,
      [ALOOMA.PARAMS.IS_APP_LOG]: 'true',
    }),
    trackGoogleTagEvent('signup'),
  ]);
};

export default guestSignUpHandler;
