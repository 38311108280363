import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  isLoaded: false,
  page: 1,
  isLastPage: false,
  isFetching: false,
};

export const promoStoreCouponsSlice = createSlice({
  name: 'promoStoreCoupons',
  initialState,
  reducers: {
    setPromoStoreCoupons: (state, { payload }) => ({
      items: payload.page === 1 ? payload.items : [...state.items, ...payload.items],
      isLoaded: true,
      page: payload.page,
      isLastPage: payload.isLastPage,
      isFetching: false,
    }),
    setIsFetching: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
    clearPromoStoreCoupons: () => ({
      ...initialState,
    }),
  },
});

export default promoStoreCouponsSlice.reducer;
