import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BrowserChromeIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <circle cx="12.1333" cy="12.0037" r="4" fill="currentColor" />
      <path
        d="M6.43324 10.1347C7.22124 7.73866 9.47524 6.00366 12.1312 6.00366H22.3832C20.3042 2.42266 16.4372 0.00366211 12.0062 0.00366211C8.40124 0.00366211 5.17024 1.60866 2.96924 4.13366L6.43324 10.1347Z"
        fill="currentColor"
      />
      <path
        d="M13.3613 17.8766C12.9643 17.9596 12.5543 18.0036 12.1333 18.0036C9.9163 18.0036 7.9823 16.7926 6.9433 15.0006L6.9373 15.0036L1.6873 5.91162C0.628301 7.69962 0.00830078 9.77862 0.00830078 12.0036C0.00830078 17.9126 4.3043 22.8246 9.9343 23.8116L13.3613 17.8766Z"
        fill="currentColor"
      />
      <path
        d="M16.5971 8.00366C17.5511 9.06666 18.1371 10.4657 18.1371 12.0037C18.1371 13.0957 17.8391 14.1177 17.3271 15.0007L17.3331 15.0037L12.1411 23.9967C18.6981 23.9267 24.0121 18.5767 24.0121 12.0037C24.0121 10.5997 23.7571 9.25666 23.3121 8.00366L16.5971 8.00366Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BrowserChromeIcon;
