import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ConeIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'primary3',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M11.5 2L5 16.5L1 18L12.5 22.5L22.5 18L18.5 16.5L11.5 2Z"
        fill={getColor(secondaryColor)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7364 2.94642C11.7199 2.95669 11.6997 2.97436 11.6822 3.01467L10.338 6.11215C11.3235 6.2086 12.3162 6.2086 13.3017 6.11215L11.9574 3.01466C11.94 2.97436 11.9198 2.95669 11.9033 2.94642C11.8831 2.93384 11.8542 2.92438 11.8198 2.92438C11.7855 2.92438 11.7566 2.93384 11.7364 2.94642ZM14.0091 7.7422C12.5558 7.93192 11.0839 7.93192 9.63057 7.7422L8.02328 11.4458C10.5384 11.8469 13.1013 11.8469 15.6164 11.4458L14.0091 7.7422ZM16.3136 13.0524C13.3409 13.578 10.2988 13.578 7.32606 13.0524L5.72875 16.733C9.73845 17.643 13.9012 17.643 17.9109 16.733L16.3136 13.0524ZM10.1228 2.33788C10.7671 0.85322 12.8726 0.853209 13.5169 2.33788L19.5929 16.3383C19.9503 17.162 19.4771 18.1086 18.6038 18.3171C14.1438 19.3817 9.49587 19.3817 5.03585 18.3171C4.16254 18.1086 3.68937 17.162 4.04682 16.3383L10.1228 2.33788Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8666 15.679L23.2287 16.9143C24.1025 17.1617 24.1643 18.3766 23.3201 18.7114L12.5991 22.9634C12.3792 23.0506 12.1348 23.0526 11.9135 22.9691L0.629264 18.7109C-0.217691 18.3913 -0.180084 17.1806 0.68507 16.9142L4.67795 15.6845L5.17829 17.3092L3.2795 17.894L12.2421 21.2761L20.6363 17.947L18.4034 17.3147L18.8666 15.679Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ConeIconFilled;
