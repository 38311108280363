export const QUERY_PARAMS = {
  categoryIds: 'categoryIds',
  code: 'code',
  second: 'second',
  userId: 'userId',
  error: 'error',
  lpTest: 'lp_test',
  errorDescription: 'error_description',
  errorUri: 'error_uri',
  filter: {
    earnings: 'filter[earnings]',
    payouts: 'filter[payouts]',
  },
  installed: 'installed',
  logIn: 'login',
  minimalOfferRate: 'minimal_offer_rate',
  modals: 'modals',
  resetPasswordToken: 'reset_password_token',
  retailers: 'retailers',
  scope: 'scope',
  signUp: 'signup',
  token: 't',
  uidToken: 'ut',
  utmCampaign: 'utm_campaign',
  utmSource: 'utm_source',
  watchDemoAgain: 'watch-demo-again',
  xuid: 'xuid',
  modalName: 'modalName',
  modalData: 'modalData',
  loadError: 'loadError',
  biscotti: 'biscotti',
  query: 'query',
  list: 'list',
  itemCount: 'itemCount',
  saveTimestamp: 'saveTimestamp',
  searchTooltip: 'searchTooltip',
  landingAbTest: 'landingAbTest',
};
