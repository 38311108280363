import getLanguage from 'functions/getLanguage';
import { oldApiInstance } from 'functions/request';
import { authenticateUser } from './authentication';
import { handleErrorFromServer } from './handleErrorFromServer';
import guestConvertHandler from './guestConvertHandler';

const convertFromGuest = data => oldApiInstance.put('/user/convert_from_guest', data).catch(handleErrorFromServer);

const setGreetingModalShown = () =>
  oldApiInstance
    .put('/user/tutorial_state/dashboard', {
      greeting_modal_shown: true,
    })
    .catch(() => {});

export const convertUserFromGuest = async ({ email, password }) => {
  const userData = {
    email,
    password,
    xuid: localStorage.getItem('xuid'),
    language: getLanguage(),
  };

  return convertFromGuest(userData).then(async ({ data }) => {
    authenticateUser({ is_converted_from_guest: true, ...data });
    await guestConvertHandler(data);
    await setGreetingModalShown();

    return data;
  });
};
