import getLanguage from 'functions/getLanguage';
import getRecaptchaToken from 'functions/getRecaptchaToken';
import { getReferralLinkParams } from 'functions/redirectLink';
import { oldApiInstance } from 'functions/request';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import CConsole from 'functions/CConsole';
import retry from 'functions/retry';
import sendRollbarError from 'functions/sendRollbarError';
import { authenticateUser } from './authentication';
import guestSignUpHandler from './guestSignUpHandler';

const guestSignUpWithRetry = data => {
  return retry(() => oldApiInstance.post('/guest_sign_up', data), 5);
};

const createGuestUser = async () => {
  try {
    const userData = getReferralLinkParams();
    const recaptchaToken = await getRecaptchaToken('SIGNUP');
    const response = await guestSignUpWithRetry({
      ...userData,
      'g-recaptcha-response': recaptchaToken,
      language: getLanguage(),
    });

    if (!response?.data) throw new Error('/guest_sign_up response is corrupted');
    authenticateUser(response.data);
    await guestSignUpHandler(response.data);
    await Alooma.UNSAFE_trackAloomaEvent(ALOOMA.EVENT_NAMES.STARTED_SIGNUP_TUTORIAL);
  } catch (error) {
    CConsole.error(error);
    sendRollbarError('createGuestUser issue', { error });
  }
};

export default createGuestUser;
