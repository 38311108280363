import {
  TITLE_SLUG_FEATURED,
  TITLE_SLUG_WE_ARE_LOVING,
  TITLE_SLUG_CASHBACK,
  TITLE_SLUG_TRANDING,
} from 'consts/retailersСategoryTitle';

const SECTIONS = {
  COUPONS_NEWEST: 'newest',
  COUPONS_OF_RETAILER: 'retailer',
  COUPONS_POPULAR: 'popular',
  COUPONS_FAVORITES: 'favorites',
  CASHBACK: TITLE_SLUG_CASHBACK,
  FEATURED: TITLE_SLUG_FEATURED,
  RELATED: 'related',
  TRENDING: TITLE_SLUG_TRANDING,
  WE_ARE_LOVING: TITLE_SLUG_WE_ARE_LOVING,
  SEARCH: 'search',
  HEADER: 'header',
  HEADER_SEARCH: 'header_search',
  NOTES: 'notes',
  FAQ: 'faq',
  NEW_SALES: 'new_sales',
  PAGE_MENU: 'page_menu',
  NOTIFICATION_SETTINGS: 'notification_settings',
  NAME: 'name',
  DESCRIPTION: 'description',
  MULTI_EDIT_BAR: 'multi_edit_bar',
  FOOTER: 'footer',
  SAVE_MONEY: 'save_money',
  ADD_TO_YOUR_DESKTOP: 'add_to_your_desktop',
  WHY_YOULL_LOVE_IT: 'why_youll_love_it',
  SAVED_ITEMS: 'saved_items',
  OTHER_ITEMS: 'other_items',
  STORES: 'stores',
  RECENT_SEARCHES: 'recent_searches',
  TRENDING_NOW: 'trending_now',
  FAVORITE_STORES: 'favorite_stores',
  DEFAULT_FAVORITE_STORES: 'default_favorite_stores',
  LISTS: 'lists',
  LAST_SAVED: 'last_saved',
};

export default SECTIONS;
