import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BookmarkIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 21 20"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16699 3.28322C2.16699 1.46995 3.62529 0 5.42418 0H15.5765C17.3754 0 18.8337 1.46995 18.8337 3.28322V18.1062C18.8337 18.9099 17.9335 19.3853 17.2697 18.932L10.7066 14.4501L3.709 18.9632C3.04356 19.3924 2.16699 18.9147 2.16699 18.1228V3.28322ZM5.42418 1.44974C4.4196 1.44974 3.60523 2.27062 3.60523 3.28322V17.309L10.7292 12.7144L17.3954 17.2667V3.28322C17.3954 2.27062 16.581 1.44974 15.5765 1.44974H5.42418Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BookmarkIcon;
