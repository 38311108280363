import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const AndroidIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 18 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M12.2726 2.73017L13.4076 1.02717C13.5606 0.798171 13.4986 0.487171 13.2686 0.334171C13.0376 0.181171 12.7276 0.242171 12.5756 0.473171L11.3896 2.25217C10.6546 1.93117 9.84458 1.75017 8.99158 1.75017C8.13858 1.75017 7.32858 1.93117 6.59358 2.25217L5.40758 0.473171C5.25458 0.243171 4.94458 0.181171 4.71458 0.334171C4.48458 0.487171 4.42258 0.798171 4.57558 1.02717L5.71058 2.73017C4.07458 3.80217 2.99158 5.64817 2.99158 7.75017H14.9916C14.9916 5.64817 13.9086 3.80217 12.2726 2.73017Z"
        fill="currentColor"
      />
      <path
        d="M2.99158 18.7502C2.99158 19.3022 3.43958 19.7502 3.99158 19.7502H4.99158V22.7502C4.99158 23.3022 5.43958 23.7502 5.99158 23.7502C6.54358 23.7502 6.99158 23.3022 6.99158 22.7502V19.7502H10.9916V22.7502C10.9916 23.3022 11.4396 23.7502 11.9916 23.7502C12.5436 23.7502 12.9916 23.3022 12.9916 22.7502V19.7502H13.9916C14.5436 19.7502 14.9916 19.3022 14.9916 18.7502V8.75024H2.99158V18.7502Z"
        fill="currentColor"
      />
      <path
        d="M0.991577 8.75024C0.439577 8.75024 -0.00842285 9.19824 -0.00842285 9.75024V14.7502C-0.00842285 15.3022 0.439577 15.7502 0.991577 15.7502C1.54358 15.7502 1.99158 15.3022 1.99158 14.7502V9.75024C1.99158 9.19824 1.54358 8.75024 0.991577 8.75024Z"
        fill="currentColor"
      />
      <path
        d="M16.9916 8.75024C16.4396 8.75024 15.9916 9.19824 15.9916 9.75024V14.7502C15.9916 15.3022 16.4396 15.7502 16.9916 15.7502C17.5436 15.7502 17.9916 15.3022 17.9916 14.7502V9.75024C17.9916 9.19824 17.5436 8.75024 16.9916 8.75024Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AndroidIcon;
