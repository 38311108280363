import { useState } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_TAG } from 'consts/defaultImages';
import { StyledImage } from './TagImage.style';

const MIN_IMG_SIZE = 20;

// mainSrc - the main tag image
// secondarySrc - the second tag image, for example the tag has images for the slider
// backupSrc - usually the retailer's logo
const TagImage = ({ mainSrc, secondarySrc, backupSrc, objectFit, ...rest }) => {
  const [src, setSrc] = useState(mainSrc);

  const onErrorHandler = () => {
    if (secondarySrc && src === mainSrc) {
      setSrc(secondarySrc);

      return;
    }

    if (backupSrc && src !== backupSrc && src !== DEFAULT_TAG) {
      setSrc(backupSrc);

      return;
    }

    setSrc(DEFAULT_TAG);
  };

  const onLoadHandler = e => {
    // need to check only scraped images, as they may not be correct
    if ([backupSrc, DEFAULT_TAG].includes(src)) return;

    const img = e.target;
    const minImgSize = Math.min(img.naturalHeight, img.naturalWidth);

    // the image is big enough to be a product image
    if (minImgSize > MIN_IMG_SIZE) return;

    if (secondarySrc && src === mainSrc) {
      setSrc(secondarySrc);

      return;
    }

    if (backupSrc) {
      setSrc(backupSrc);

      return;
    }

    setSrc(DEFAULT_TAG);
  };

  return (
    <StyledImage
      src={src}
      onError={onErrorHandler}
      onLoad={onLoadHandler}
      $objectFit={src === backupSrc ? 'scale-down' : objectFit}
      {...rest}
    />
  );
};

TagImage.propTypes = {
  mainSrc: PropTypes.string.isRequired,
  secondarySrc: PropTypes.string,
  backupSrc: PropTypes.string.isRequired,
  objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down', 'initial']),
};

TagImage.defaultProps = {
  secondarySrc: undefined,
  objectFit: 'cover',
};

export default TagImage;
