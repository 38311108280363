import styled from 'styled-components';
import { getColor } from '@karma/components/colors';
import Stack from '@karma/components/Stack';
import Typography from '@karma/components/Typography';
import { COUPONS_TYPES } from 'consts/couponTypes';

export const COUPON_STATES = {
  [COUPONS_TYPES.exclusive]: {
    secondaryTextColor: getColor('neutral6'),
    accent: getColor('primary1'),
  },
  [COUPONS_TYPES.sponsored]: {
    secondaryTextColor: getColor('primary1'),
    accent: getColor('shades2'),
  },
};

export const Container = styled(Stack)`
  position: relative;
  width: 100%;
  height: 140px;
  overflow: hidden;
  border-radius: 5px;
  background-color: ${getColor('neutral6')};

  transition: box-shadow 0.3s ease-out, border-color 0.3s ease-out;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
`;

export const MainContent = styled(Stack)`
  padding: 12px 8px 12px 12px;
  flex: auto;
  overflow: hidden;
  background-color: ${getColor('neutral6')};
  border-top: 1px ${getColor('primary2')};
  border-right: 1px ${getColor('primary2')};
  border-bottom: 1px ${getColor('primary2')};
  border-left: 1px ${getColor('neutral6')};
  border-style: dashed;
  border-radius: 0px 5px 5px 0px;
`;

export const Body = styled(Stack)`
  width: 100%;
  padding-right: 16px;
  -webkit-line-clamp: 2;
`;

export const CouponDescription = styled(Typography)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SideBar = styled(Stack)`
  padding: 10px 6px;
  border-radius: 5px 0px 0px 5px;
  min-width: 25px;
  background: ${({ $state }) => COUPON_STATES[$state]?.accent};
  color: ${({ $state }) => COUPON_STATES[$state]?.secondaryTextColor};
`;

export const EmptySidebar = styled.div`
  width: 25px;
  min-width: 25px;
  height: 100%;
  border-top: 1px ${getColor('primary2')};
  border-left: 1px ${getColor('primary2')};
  border-bottom: 1px ${getColor('primary2')};
  border-right: 1px ${getColor('neutral6')};
  border-radius: 5px 0px 0px 5px;
  border-style: dashed;
`;

export const StyledSideBarText = styled(Typography)`
  writing-mode: tb-rl;
  transform: rotate(-180deg);
`;

export const CouponInfoContainer = styled(Stack)`
  padding-bottom: 8px;
  flex-grow: 1;
`;

export const RetailerNameTitle = styled(Typography)`
  max-width: 100%;
`;

export const ButtonContainer = styled(Stack)`
  margin-top: auto;
`;
