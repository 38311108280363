import { useState } from 'react';
import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import Modal from 'components/templates/Modal/Modal';
import { DAY_IN_MILLISECONDS } from 'consts/time';
import { ITEMS } from 'consts/localStorage';
import ModalBodyAuthenticate from 'components/molecules/ModalBodyAuthenticate/ModalBodyAuthenticate';
import { AUTHENTICATE_TYPE } from 'consts/authenticateModal';

const mapAloomaModalSource = {
  [AUTHENTICATE_TYPE.LOGIN]: ALOOMA.SOURCE_PAGES.MODAL_LOGIN,
  [AUTHENTICATE_TYPE.SIGN_UP]: ALOOMA.SOURCE_PAGES.MODAL_CONGRATULATE_SIGN_UP,
};

const ModalCongratulateCompleteSignUp = props => {
  const [authState, setAuthState] = useState(AUTHENTICATE_TYPE.SIGN_UP);
  const { closeModal } = props;

  const onCloseHandler = () => {
    localStorage.setItem(ITEMS.completeSignUpModalDelay, JSON.stringify(Date.now() + DAY_IN_MILLISECONDS));
    closeModal();
  };

  return (
    <Modal
      source={mapAloomaModalSource[authState]}
      aloomaModalType={ALOOMA.MODAL_TYPES.WINDOW}
      aloomaModalName={mapAloomaModalSource[authState]}
      onClose={onCloseHandler}
    >
      <ModalBodyAuthenticate {...props} authState={authState} setAuthState={setAuthState} isGuestSignup />
    </Modal>
  );
};

ModalCongratulateCompleteSignUp.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalCongratulateCompleteSignUp;
