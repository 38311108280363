import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { API_URL, NEW_API_URL, JAVA_API_URL, LAMBDA_API_URL } from '../constants/env';
import { X_PLATFORMS } from '../constants/axios';

let customHeadersInterceptorID;
let customAxios;

export function getCustomAxios() {
  return customAxios || axios;
}

export function setCustomAxios(cAxios) {
  customAxios = cAxios;
}

export function setUnauthorizedHandler(handler, delegateError) {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (typeof handler === 'function') {
        if (delegateError) {
          handler(error);
        } else if (error.response && error.response.status === 401) {
          handler();
        }
      }

      return Promise.reject(error);
    }
  );
}

interface IHeaderValues {
  token: string | null;
  platform: keyof typeof X_PLATFORMS;
  version: string;
  browser: string | undefined;
  [key: string]: string; // Allows any additional headers
}

export function addCustomHeaders(getHeaderValues: () => IHeaderValues) {
  axios.interceptors.request.eject(customHeadersInterceptorID);

  customHeadersInterceptorID = axios.interceptors.request.use(
    config => {
      const { token, platform, version, browser, ...rest } = getHeaderValues();
      const { url } = config;
      const isApiUrl =
        url?.includes(API_URL) ||
        url?.includes(NEW_API_URL) ||
        url?.includes(JAVA_API_URL) ||
        url?.includes(LAMBDA_API_URL);

      if (isApiUrl) {
        if (token) config.headers.common['Authorization'] = `Bearer ${token}`;

        config.headers.common['Content-Type'] = 'application/json';
      }

      if (isApiUrl || url?.includes('.amazonaws.com/')) {
        if (version) config.headers.common['X-Platform-Version'] = version;
        if (platform) config.headers.common['X-Platform'] = platform;
        if (browser) config.headers.common['X-Platform-Browser'] = browser;

        config.headers.common['X-Request-Id'] = uuid();

        Object.entries(rest).forEach(([key, value]) => {
          if (key && value) config.headers.common[key] = value;
        });
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
}
