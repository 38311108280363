import { Route } from 'react-router-dom';
import { PATHS } from 'consts/paths';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import UnauthorizedRoute from 'components/organisms/UnauthorizedRoute/UnauthorizedRoute';
import { SSR_ROUTES } from 'routes';

const routesProps = [
  {
    Component: Route,
    path: PATHS.ABOUT_US,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.ABOUT_US}>
        <SSR_ROUTES.AboutUsPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.CAREERS,
    exact: true,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.CAREERS}>
        <SSR_ROUTES.CareersPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.KARMA_CASH_SINGLE,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.DESIGNATED_STORE}>
        <SSR_ROUTES.WebsiteRetailerPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.KARMA_CASH,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.CASHBACK_PICKS}>
        <SSR_ROUTES.WebsiteRetailersPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.CONTACT,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.CONTACT}>
        <SSR_ROUTES.ContactUsPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.GET_THE_BUTTON,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.GET_THE_BUTTON}>
        <SSR_ROUTES.GetTheButtonPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.PRIVACY,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.PRIVACY}>
        <SSR_ROUTES.PrivacyPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.COMEET_PRIVACY,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.COMEET_PRIVACY}>
        <SSR_ROUTES.ComeetPrivacyPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.SHARE,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.WISHLIST}>
        <SSR_ROUTES.SharedListPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.SHOP,
    exact: true,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.SHOP}>
        <SSR_ROUTES.SEOShopPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.SEO_TAG,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.SHOP_PRODUCT}>
        <SSR_ROUTES.SEOTagPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.SEO_TAG_2,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.SHOP_PRODUCT}>
        <SSR_ROUTES.SEOTagPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.SEO_PROMO,
    exact: true,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.SEO_PROMO}>
        <SSR_ROUTES.SeoPromoPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.SEO_PROMO_SEARCH,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.SEO_PROMO_SEARCH}>
        <SSR_ROUTES.SeoPromoSearchPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.SEO_PROMO_STORE,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.SEO_PROMO_STORE}>
        <SSR_ROUTES.SeoPromoStorePage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: Route,
    path: PATHS.TERMS,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.TERMS}>
        <SSR_ROUTES.TermsPage {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
  },
  {
    Component: UnauthorizedRoute,
    exact: true,
    path: [PATHS.WEBSITE_LANDING_PATH, PATHS.GOOGLE_CACHE_PATH],
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.LANDING_MAIN}>
        <SSR_ROUTES.WebsiteLandingKarmaAI {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
    relatedAuthorizedLink: PATHS.DASHBOARD,
  },
  {
    Component: UnauthorizedRoute,
    exact: true,
    path: PATHS.WEBSITE_LANDING_MARKETING_2,
    render: routeProps => (
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.LANDING_PAGE_NEW}>
        <SSR_ROUTES.WebsiteLandingKarmaMarketing {...routeProps} />
      </Alooma.SourceContext.Provider>
    ),
    relatedAuthorizedLink: PATHS.DASHBOARD,
  },
];

const serverRoutes = routesProps.map(({ Component, ...rest }) => <Component key={rest.path} {...rest} />);

export default serverRoutes;
