import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const NotificationsIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M13.0009 2.54059V0H11.0009V2.52133C8.54782 2.75592 6.86032 3.83324 5.77648 5.20818C4.58312 6.72206 4.18036 8.51873 4.17115 9.74227V10.8479C4.17115 12.4011 3.5689 13.8937 2.49106 15.0119L2.1572 15.3582C1.88774 15.6378 1.73718 16.011 1.73718 16.3992V19.5265H7.79291C8.24424 21.4322 9.95672 22.85 12.0004 22.85C14.044 22.85 15.7565 21.4322 16.2078 19.5265H22.2637V16.3992C22.2637 16.011 22.1131 15.6378 21.8437 15.3582L21.5098 15.0119C20.432 13.8937 19.8297 12.4011 19.8297 10.8479V10.5343C19.8297 7.39939 18.5504 5.33537 16.876 4.08468C15.6211 3.14731 14.1881 2.69715 13.0009 2.54059Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default NotificationsIconFilled;
