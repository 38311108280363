import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CouponIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M20.9707 4.5H1.9707V8C4.3707 8 4.9707 10.6667 4.9707 12C4.9707 14.8 2.63737 15.8333 1.4707 16V19.5H19.4707C21.4707 19.5 22.6374 18.5 22.9707 18V7C22.9707 5.4 21.6374 4.66667 20.9707 4.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.462891 3.7467H20.6879C22.2619 3.7467 23.5379 5.02269 23.5379 6.5967V17.6342C23.5379 19.2082 22.2619 20.4842 20.6879 20.4842H0.462891V15.1229L1.31289 15.1229C2.74342 15.1229 4.01869 13.8429 4.01869 12.1155C4.01869 10.388 2.74342 9.10801 1.31289 9.10801L0.462891 9.10801V3.7467ZM2.16289 5.4467V7.49434C4.23039 7.92154 5.71869 9.87842 5.71869 12.1155C5.71869 14.3525 4.23039 16.3094 2.16289 16.7366V18.7842H20.6879C21.323 18.7842 21.8379 18.2693 21.8379 17.6342V6.5967C21.8379 5.96158 21.323 5.4467 20.6879 5.4467H2.16289Z"
        fill="currentColor"
      />
      <path
        d="M18.8629 12.1154C18.8629 13.037 18.1158 13.7842 17.1941 13.7842C16.2725 13.7842 15.5254 13.037 15.5254 12.1154C15.5254 11.1938 16.2725 10.4467 17.1941 10.4467C18.1158 10.4467 18.8629 11.1938 18.8629 12.1154Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CouponIconFilled;
