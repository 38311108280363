import { useEffect } from 'react';
import { getCookieValue } from 'functions/cookies';
import { COOKIES } from 'consts/cookies';
import { processReferralLink } from 'functions/redirectLink';

const useProcessReferralLinkOnPublicPage = () => {
  useEffect(() => {
    if (getCookieValue(COOKIES.logged)) return;
    processReferralLink();
  }, []);
};

export default useProcessReferralLinkOnPublicPage;
