import { createSlice } from '@reduxjs/toolkit';
import {
  getRecentGlobalSearches,
  removeRecentGlobalSearch,
  setRecentGlobalSearch,
} from 'functions/recentGlobalSearchesFunctions';

const initialState = {
  isHeaderSearchActive: false,
  searchExpanded: false,
  recentSearches: [],
  loaded: false,
};

export const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setIsHeaderSearchActive: (state, { payload }) => ({
      ...state,
      isHeaderSearchActive: payload,
    }),
    setSearchExpanded: (state, { payload }) => ({
      ...state,
      searchExpanded: payload,
    }),
    addNewRecentSearch: (state, { payload }) => ({
      ...state,
      recentSearches: setRecentGlobalSearch(payload),
    }),
    removeRecentSearch: (state, { payload }) => ({
      ...state,
      recentSearches: removeRecentGlobalSearch(payload),
    }),
    loadRecentSearches: state => ({
      ...state,
      recentSearches: getRecentGlobalSearches(),
      loaded: true,
    }),
  },
});

export default globalSearchSlice.reducer;
