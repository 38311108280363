import { call, put } from 'redux-saga/effects';
import { clearPromoSearchTags, setPromoSearchTags, setIsFetching } from '../actions';
import { fetchPromoSearchTagsApi } from '../api';

const PER_PAGE = 20;

function* fetchPromoSearchTagsWorker({ payload }) {
  const { search, page } = payload;

  try {
    if (page === 1) {
      yield put(clearPromoSearchTags());
    } else {
      yield put(setIsFetching(true));
    }

    const response = yield call(fetchPromoSearchTagsApi, { search, page, perPage: PER_PAGE });

    yield put(
      setPromoSearchTags({
        items: response.data.items || [],
        page,
        isLastPage: response.data.items.length < PER_PAGE,
      }),
    );
  } catch (error) {
    yield put(setPromoSearchTags({ items: [], page, isLastPage: true }));
    console.error(error);
  }
}

export default fetchPromoSearchTagsWorker;
