import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@karma/components/Typography';
import { COLOR_THEMES } from '@karma/components/themes';
import { ThemeProvider } from 'styled-components';
import { Button, Stack } from '@karma/components';
import { useSelector } from 'react-redux';
import { getRedirectLinkToPackage, getRedirectLinkToRetailer } from '@karma/utils/functions/redirectLink';
import { BagIcon, CopyIcon } from '@karma/components/icons';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { COUPONS_TYPES } from 'consts/couponTypes';
import useGetLastSuccessUseOfCoupon from 'hooks/useGetLastSuccessUseOfCoupon';
import { OFFER_TYPES } from 'consts/coupons';
import { getUserId } from 'store/user/selectors';
import { ReferralSourceContext } from 'contexts/ReferralSourceContext';
import useAction from 'hooks/useAction';
import { showSuccessAlert } from 'store/alerts/actions';
import copyToClipboard from 'functions/copyToClipboard';
import {
  Container,
  CouponDescription,
  MainContent,
  SideBar,
  StyledSideBarText,
  RetailerNameTitle,
  EmptySidebar,
  CouponInfoContainer,
  ButtonContainer,
} from './CardCoupon.style';

const getColorState = coupon => {
  const { ad_package_id: adPackageId, exclusive } = coupon || {};

  if (adPackageId) return COUPONS_TYPES.sponsored;
  if (exclusive) return COUPONS_TYPES.exclusive;

  return COUPONS_TYPES.coupon;
};

const CardCoupon = ({ coupon, source = '' }) => {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation();
  const userId = useSelector(getUserId);
  const contextSource = useContext(ReferralSourceContext);
  const showSuccessAlertFunc = useAction(showSuccessAlert);

  const {
    description,
    last_success_use: lastSuccessUse,
    retailer_name: retailerName,
    title,
    offer_type: offerType,
    ad_package_id: adPackageId,
    code,
    retailer_id: retailerId,
    retailer_stake: retailerStake,
    stake,
    plus_stake: plusStake,
  } = coupon || {};

  const contextDataAttrs = Alooma.useContextDataAttrs();
  const lastSuccessUseText = useGetLastSuccessUseOfCoupon(lastSuccessUse);
  const $state = getColorState(coupon);

  const showLastSuccessUse = $state !== COUPONS_TYPES.sponsored && !title && offerType !== OFFER_TYPES.KARMA_CASH;
  const isAdPackage = adPackageId || offerType === OFFER_TYPES.KARMA_CASH;
  const isCoupon = offerType === OFFER_TYPES.COUPON;

  const clickHandler = () => {
    const commonParams = {
      cashback: !!(userId && retailerStake),
      userId,
      ruleName: retailerName,
      isCoupon,
      isCouponDeal: !stake && !plusStake && !retailerStake,
      source: source || contextSource,
    };
    const referrerLink = adPackageId
      ? getRedirectLinkToPackage({ ...commonParams, packageId: adPackageId })
      : getRedirectLinkToRetailer({ ...commonParams, retailerId });

    window.open(referrerLink);
  };

  const copyCouponHandler = e => {
    e.stopPropagation();
    setClicked(true);
    copyToClipboard(code);
    showSuccessAlertFunc({ message: t('app:couponCopied') });
  };

  return (
    <ThemeProvider theme={{ color: COLOR_THEMES.LIGHT }}>
      <Container
        direction="row"
        justifyContent="stretch"
        alignItems="stretch"
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.COUPON}
        onClick={clickHandler}
      >
        {[COUPONS_TYPES.sponsored, COUPONS_TYPES.exclusive].includes($state) ? (
          <SideBar $state={$state} direction="column">
            <StyledSideBarText variant="labelSmallUppercase">
              {t($state === COUPONS_TYPES.exclusive ? 'app:exclusive' : 'app:sponsored')}
            </StyledSideBarText>
          </SideBar>
        ) : (
          <EmptySidebar />
        )}
        <MainContent direction="column" alignItems="flex-start" justifyContent="flex-start">
          <RetailerNameTitle variant="paragraphMediumLarge" noWrap bMargin={4}>
            {retailerName}
          </RetailerNameTitle>

          <CouponInfoContainer direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={8}>
            <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
              {!!title && (
                <Typography variant="paragraphSmall" fontWeight={500}>
                  {title}
                </Typography>
              )}
              <CouponDescription variant="paragraphSmall">{description}</CouponDescription>
            </Stack>
            {showLastSuccessUse && (
              <Typography
                variant="labelSmall"
                color="neutral2"
                style={{
                  marginTop: 'auto',
                }}
              >
                {lastSuccessUseText}
              </Typography>
            )}
          </CouponInfoContainer>

          {isAdPackage && (
            <ButtonContainer alignItems="flex-start">
              <Button type="underlinedText" size="verySmall" Icon={BagIcon} iconPosition="right">
                {t('app:shopNow')}
              </Button>
            </ButtonContainer>
          )}

          {!isAdPackage && (
            <ButtonContainer justifyContent="space-between" fullWidth>
              {clicked ? (
                <Typography variant="labelSmall">{`${t('app:code')}: ${code}`}</Typography>
              ) : (
                <Button
                  {...contextDataAttrs}
                  data-alooma-element={ALOOMA.ELEMENTS.GET_COUPON}
                  type="underlinedText"
                  size="verySmall"
                  Icon={CopyIcon}
                  iconPosition="right"
                  onClick={copyCouponHandler}
                >
                  {t('app:getCoupon')}
                </Button>
              )}
              <Button type="underlinedText" size="verySmall">
                {t('app:goToStore')}
              </Button>
            </ButtonContainer>
          )}
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

export default CardCoupon;
