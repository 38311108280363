import { createGlobalStyle, css, keyframes } from 'styled-components';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getColor } from '@karma/components/colors';
import { BLACK_01, BLACK_02, BLACK_08, BLACK_1, WHITE } from 'consts/colors';
import { STOP_SCROLL_CLASSNAME } from 'consts/globalSearch';
import { HIDE_WISEPOPS_CLASSNAME } from 'consts/querySelectors';

const blurAnimation = keyframes`
  0% {
    filter: blur(100px);
  }

  100% {
    filter: blur(0);
  }
`;

export const lazyLoadImageStyles = css`
  animation: ${blurAnimation} ease-out 0.5s;
  animation-fill-mode: forwards;
`;

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    font-family: Roboto, sans-serif;
    background: ${({ isDarkPage }) => (isDarkPage ? getColor('primary1') : getColor('neutral6'))};
    color: ${({ isDarkPage }) => (isDarkPage ? getColor('primary2') : getColor('primary1'))};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    overscroll-behavior: none;
      
    &.${STOP_SCROLL_CLASSNAME} {
        overflow: hidden;
    }

    &.${HIDE_WISEPOPS_CLASSNAME} #wisepops-root {
        display: none;
    }

    * {
      font-family: inherit;
      scrollbar-width: thin;
    }
  }

  body.modal-open > div[role='dialog'] {
    & > .modal-backdrop,
    & > .modal {
      z-index: 2020;
    }
  }

  img {
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  //h2 {
  //  font-weight: 600;
  //  line-height: calc(24 / 20);
  //  margin-bottom: 20px;
  //}
  //
  //h3 {
  //  font-weight: 500;
  //  line-height: calc(22 / 18);
  //  &.underline {
  //    padding-bottom: 18px;
  //  }
  //}
  //
  //h4 {
  //  font-weight: 500;
  //  line-height: calc(20 / 16);
  //  margin-bottom: 18px;
  //  &.underline {
  //    padding-bottom: 18px;
  //  }
  //}
  //
  //h5 {
  //  font-weight: 600;
  //}
  //
  //h6 {
  //  font-weight: 300;
  //  line-height: calc(12 / 10);
  //  margin-bottom: 12px;
  //}
  //
  //hr {
  //  margin-top: 30px;
  //  margin-bottom: 30px;
  //}

  a {
    cursor: pointer;
    color: inherit;

    &:hover {
      text-decoration: none;
      color: unset;
    }
  }

  //p {
  //  font-weight: 400;
  //}

  input,
  textarea,
  button,
  a {
    &:focus {
      outline: none;
      text-decoration: none;
    }
  }

  .modal-open.iosBugFixCaret {
    position: fixed;
    width: 100%;
  }

  .modal-open .modal {
    display: block;
  }

  [role="button"] {
    cursor: pointer;
  }

  /* popup */

  .modal {
    z-index: 1070;
  }

  .modal-backdrop {
    background-color: ${BLACK_1};
    z-index: 1060;
  }
  .modal-backdrop.no-background {
    background: none;
    z-index: 1100;
  }

  .modal-backdrop.dark-background {
    background: ${BLACK_08};
    z-index: 1040;
  }
  
  .fade.in {
    opacity: 1;
  }

  .popup {
    padding: 0px 10px !important;

    .modal-dialog.error-building-tag {
      max-width: 800px;
    }

    .modal-dialog {
      max-width: 370px;
    }

    .modal-content {
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 ${BLACK_01};
      border: solid 1px ${BLACK_02};
      padding: 30px;
      border-radius: 10px;

      .modal-header,
      .modal-body,
      .modal-footer {
        padding: 0px;
        position: static;

        .btn.btn-primary.btn-block {
          color: ${WHITE};
        }
      }

      .close {
        float: none;
        position: absolute;
        z-index: 1;
        top: 19px;
        right: 19px;
        opacity: 1;
      }

      .modal-body {
        h3 {
          margin-bottom: 28px;
        }
      }
    }

    .scroll {
      max-height: 390px;
    }

    p {
      margin-bottom: 20px;
    }

    .image {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 575px) {
    .popup {
      padding: 0px 10px;
      .modal-dialog {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
      }

      .modal-content {
        padding: 20px;

        .close {
          top: 12px;
          right: 11px;
        }

        .modal-body {
          h3 {
            margin-bottom: 18px;
          }
        }
      }

      .scroll {
        max-height: 390px;
      }
    }
  }

  .grecaptcha-badge { visibility: hidden; }
  `;

export default GlobalStyle;
