// elementRef is needed when you call this function from react-bootstrap modal (elementRef is a ref to element inside modal window)
// In other cases you can pass only 'value' prop
const copyToClipboard = (value, elementRef) => {
  // TODO can be replaced by "navigator.clipboard.writeText(value);"

  const el = document.createElement('textarea');

  el.value = value;
  if (elementRef) {
    elementRef.current.parentNode.insertBefore(el, elementRef.current);
  } else {
    document.body.appendChild(el);
  }
  el.select();
  document.execCommand('copy');
  el.parentNode.removeChild(el);
};

export default copyToClipboard;
