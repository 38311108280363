export const REF_SOURCES = {
  cashbackPicks: 'CASHBACK_PICKS_page',
  coupons: 'COUPONS_page',
  dashboard: 'dashboard',
  favorite: 'LOVING_page',
  featured: 'FEATURED_page',
  guestWallet: 'guest_wallet_page',
  home: 'HOME_page',
  retailer: 'RETAILER_page',
  seo: 'SEO_Page',
  seoPromo: 'SEO_promo_page',
  seoPromoSearch: 'SEO_promo_search_page',
  seoPromoStore: 'SEO_promo_store_page',
  shop: 'SHOP_page',
  stores: 'STORES_page',
  trending: 'TRENDING_page',
  profile: 'profile',
  wishlist: 'Wishlist',
  globalSearch: 'GLOBAL_SEARCH_page',
  marketingRedirectClosed: 'MARKETING_redirect_closed',
};
