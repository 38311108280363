import { createContext, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as ALOOMA from 'consts/alooma';
import * as COMMON from 'consts/common';
import * as userSelectors from 'store/user/selectors';
import { isClient } from 'functions/isClient';
import { TABLE_REGEX } from 'functions/isTablet';
import { getCookieValue } from 'functions/cookies';
import { COOKIES } from 'consts/cookies';
import buildUrl from 'functions/buildUrl';
import CConsole from 'functions/CConsole';
import { getWebVitalsDetails } from 'services/webVitals';
import buildCustomEvent from 'services/alooma/buildCustomEvent';
import getOrigin from 'services/alooma/getOrigin';

function getGaClientId() {
  return getCookieValue(COOKIES.gaClientId)?.substring(6);
}

const formRegistrationData = data => ({
  [ALOOMA.PARAMS.USER_ID]: data.user_id,
});

function sendWebVitalsReport(metric) {
  /* eslint-disable no-unused-vars,no-unused-expressions */
  const { name, attribution, ...metricData } = metric;
  const details = getWebVitalsDetails(metric);
  const data = { ...metricData, ...details, is_app_log: true };
  const event = buildCustomEvent(name, data);
  const url = buildUrl(process.env.REACT_APP_ALOOMA_EVENT_PATH, event);

  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
  (navigator.sendBeacon && navigator.sendBeacon(url)) || fetch(url, { method: 'GET', keepalive: true });
  CConsole.log('web vitals report:', data);
  /* eslint-enable no-unused-vars,no-unused-expressions */
}

// ! Trackers
const sendAloomaEvent = (name, data) => {
  if (!isClient()) {
    return Promise.resolve();
  }

  if (window.alooma) {
    return new Promise(resolve => {
      window.alooma.track(name, data, resolve);
    });
  }

  const event = buildCustomEvent(name, data);
  const url = buildUrl(process.env.REACT_APP_ALOOMA_EVENT_PATH, event);

  return fetch(url).catch(CConsole.error);
};

const trackClickEvent = params => {
  if (!isClient()) return;

  const defaultParams = {
    // Required
    [ALOOMA.PARAMS.ORIGIN]: getOrigin(),
    [ALOOMA.PARAMS.GA_CLIENT_ID]: getGaClientId(),
  };
  const data = { ...defaultParams, ...params };

  if (process.env.REACT_APP_ENV !== COMMON.ENV_PRODUCTION) console.log('click event:', data);
  sendAloomaEvent(ALOOMA.EVENT_NAMES.CLICK, data);
};

const trackPageViewEvent = params => {
  if (!isClient()) return;

  const defaultParams = {
    // Required
    [ALOOMA.PARAMS.ORIGIN]: getOrigin(),
    [ALOOMA.PARAMS.URL]: window.location.href,
    [ALOOMA.PARAMS.VERSION]: ALOOMA.VERSION,
    [ALOOMA.PARAMS.GA_CLIENT_ID]: getGaClientId(),
  };

  const data = { ...defaultParams, ...params };

  if (process.env.REACT_APP_ENV !== COMMON.ENV_PRODUCTION) console.log('view event:', data);
  sendAloomaEvent(ALOOMA.EVENT_NAMES.PAGE_VIEW, data);
};

const trackImpressionEvent = params => {
  if (!isClient()) return;

  const defaultParams = {
    // Required
    [ALOOMA.PARAMS.ORIGIN]: getOrigin(),
    [ALOOMA.PARAMS.URL]: window.location.href,
    [ALOOMA.PARAMS.VERSION]: ALOOMA.VERSION,
    [ALOOMA.PARAMS.GA_CLIENT_ID]: getGaClientId(),
  };

  const data = { ...defaultParams, ...params };

  if (process.env.REACT_APP_ENV !== COMMON.ENV_PRODUCTION) console.log('impression event:', data);
  sendAloomaEvent(ALOOMA.EVENT_NAMES.IMPRESSION, data);
};

const UNSAFE_trackAloomaEvent = (name, params = {}) => {
  if (!isClient() || !name) {
    return Promise.resolve();
  }

  const data = {
    [ALOOMA.PARAMS.GA_CLIENT_ID]: getGaClientId(),
    ...params,
  };

  return sendAloomaEvent(name, data);
};

// ! Contexts
const CouponIdContext = createContext();
const NotificationIdIdContext = createContext();
const PackageIdContext = createContext();
const RetailerIdContext = createContext();
const RuleIdContext = createContext();
const SectionContext = createContext();
const SourceContext = createContext();
const ModalTypeContext = createContext();
const ModalNameContext = createContext();
const TagIdContext = createContext();
const NonStandardDataContext = createContext();

// ! Hooks (common)
const useContextDataAttrs = () => {
  const couponId = useContext(CouponIdContext);
  const notificationId = useContext(NotificationIdIdContext);
  const packageId = useContext(PackageIdContext);
  const retailerId = useContext(RetailerIdContext);
  const ruleId = useContext(RuleIdContext);
  const section = useContext(SectionContext);
  const source = useContext(SourceContext);
  const tagId = useContext(TagIdContext);
  const modalType = useContext(ModalTypeContext);
  const modalName = useContext(ModalNameContext);
  const nonStandardData = useContext(NonStandardDataContext);

  return useMemo(() => {
    return {
      [ALOOMA.ATTRS.COUPON_ID]: couponId,
      [ALOOMA.ATTRS.NOTIFICATION_ID]: notificationId,
      [ALOOMA.ATTRS.PACKAGE_ID]: packageId,
      [ALOOMA.ATTRS.RETAILER_ID]: retailerId,
      [ALOOMA.ATTRS.RULE_ID]: ruleId,
      [ALOOMA.ATTRS.SECTION]: section,
      [ALOOMA.ATTRS.SOURCE]: source,
      [ALOOMA.ATTRS.TAG_ID]: tagId,
      [ALOOMA.ATTRS.MODAL_TYPE]: modalType,
      [ALOOMA.ATTRS.MODAL_NAME]: modalName,
      [ALOOMA.ATTRS.NON_STANDARD]: JSON.stringify(nonStandardData),
    };
  }, [
    couponId,
    notificationId,
    packageId,
    retailerId,
    ruleId,
    section,
    source,
    tagId,
    modalType,
    modalName,
    nonStandardData,
  ]);
};

// ! Hooks (trackers)
const useClicksTracker = () => {
  const userId = useSelector(userSelectors.getUserId);

  useEffect(() => {
    const clickHandler = event => {
      // ! NOTE: Event.path is not supplied in Firefox, Safari
      const path = event.path || (event.composedPath && event.composedPath());

      const origin =
        path &&
        path.find(
          element =>
            element.getAttribute &&
            element.getAttribute(ALOOMA.ATTRS.ELEMENT) &&
            element.getAttribute(ALOOMA.ATTRS.SOURCE),
        );

      if (origin) {
        const disabled = origin.getAttribute(ALOOMA.ATTRS.DISABLED) === 'true';

        if (!disabled) {
          const dataString = origin.getAttribute(ALOOMA.ATTRS.NON_STANDARD);
          const dataObject = (dataString && JSON.parse(dataString)) || {};

          const params = {
            // Non-standard parameters
            ...dataObject,
            // Required
            [ALOOMA.PARAMS.ELEMENT]: origin.getAttribute(ALOOMA.ATTRS.ELEMENT),
            [ALOOMA.PARAMS.SOURCE]: origin.getAttribute(ALOOMA.ATTRS.SOURCE),
            // Optional
            [ALOOMA.PARAMS.COUPON_ID]: origin.getAttribute(ALOOMA.ATTRS.COUPON_ID),
            [ALOOMA.PARAMS.NOTIFICATION_ID]: origin.getAttribute(ALOOMA.ATTRS.NOTIFICATION_ID),
            [ALOOMA.PARAMS.PACKAGE_ID]: origin.getAttribute(ALOOMA.ATTRS.PACKAGE_ID),
            [ALOOMA.PARAMS.RETAILER_ID]: origin.getAttribute(ALOOMA.ATTRS.RETAILER_ID),
            [ALOOMA.PARAMS.RULE_ID]: origin.getAttribute(ALOOMA.ATTRS.RULE_ID),
            [ALOOMA.PARAMS.SECTION]: origin.getAttribute(ALOOMA.ATTRS.SECTION),
            [ALOOMA.PARAMS.STEP]: origin.getAttribute(ALOOMA.ATTRS.STEP),
            [ALOOMA.PARAMS.TAG_ID]: origin.getAttribute(ALOOMA.ATTRS.TAG_ID),
            [ALOOMA.PARAMS.TEXT]: origin.getAttribute(ALOOMA.ATTRS.TEXT),
            [ALOOMA.PARAMS.LIST_ID]: origin.getAttribute(ALOOMA.ATTRS.LIST_ID),
            [ALOOMA.PARAMS.PRIORITY]: origin.getAttribute(ALOOMA.ATTRS.PRIORITY),
            [ALOOMA.PARAMS.ABSOLUTE_POSITION]: origin.getAttribute(ALOOMA.ATTRS.ABSOLUTE_POSITION),
            [ALOOMA.PARAMS.RELATIVE_POSITION]: origin.getAttribute(ALOOMA.ATTRS.RELATIVE_POSITION),
            [ALOOMA.PARAMS.MODAL_TYPE]: origin.getAttribute(ALOOMA.ATTRS.MODAL_TYPE),
            [ALOOMA.PARAMS.MODAL_NAME]: origin.getAttribute(ALOOMA.ATTRS.MODAL_NAME),
            [ALOOMA.PARAMS.USER_ID]: userId,
          };

          trackClickEvent(params);
        }
      }
    };

    const options = { capture: true };

    window.addEventListener('click', clickHandler, options);

    return () => {
      window.removeEventListener('click', clickHandler, options);
    };
  }, [userId]);
};

const usePageViewTracker = (page, condition = true) => {
  const contextSource = useContext(SourceContext);
  const nonStandardData = useContext(NonStandardDataContext);
  const source = page || contextSource;
  const userId = useSelector(userSelectors.getUserId);

  useEffect(() => {
    if (condition) {
      const params = {
        // Required
        [ALOOMA.PARAMS.SOURCE]: source,
        // Optional
        [ALOOMA.PARAMS.USER_ID]: userId,
        ...(nonStandardData || {}),
      };

      trackPageViewEvent(params);
    }
  }, [condition, source, userId, nonStandardData]);
};

/**
 * Effect that send "impression" alooma event
 *
 * @param {Object} params - Optional object with parameters
 * @param {String} params.source - value for PARAMS.SOURCE param
 * @param {String} params.modalType - value for MODAL_TYPE param
 * @param {String} params.modalName - value for MODAL_NAME param
 * @param {Boolean} condition - Optional condition that cancel or send event
 */
const useImpressionTracker = (params = {}, condition = true) => {
  const userId = useSelector(userSelectors.getUserId);

  const sourceContext = useContext(SourceContext);
  const modalTypeContext = useContext(ModalTypeContext);
  const modalNameContext = useContext(ModalNameContext);
  const nonStandardDataContext = useContext(NonStandardDataContext);
  const { source: _source, modalType: _modalType, modalName: _modalName, ...nonStandardParams } = params || {};

  const source = _source || sourceContext;
  const modalType = _modalType || modalTypeContext;
  const modalName = _modalName || modalNameContext;

  useEffect(() => {
    if (condition && source && modalType && modalName) {
      trackImpressionEvent({
        // Non-standard parameters
        ...(nonStandardDataContext || {}),
        ...nonStandardParams,
        // Required
        [ALOOMA.PARAMS.SOURCE]: source,
        [ALOOMA.PARAMS.MODAL_TYPE]: modalType,
        [ALOOMA.PARAMS.MODAL_NAME]: modalName,
        // Optional
        [ALOOMA.PARAMS.USER_ID]: userId,
      });
    }
  }, [condition, source, modalType, modalName, userId, nonStandardDataContext]);
};

// ! Methods
const forcedTrackClickEvent = data => {
  try {
    const REQUIRED_PARAMS = [ALOOMA.ATTRS.ELEMENT, ALOOMA.ATTRS.SOURCE];

    REQUIRED_PARAMS.forEach(paramName => {
      if (!Object.prototype.hasOwnProperty.call(data, paramName)) {
        throw new Error(`The data object doesn't have '${paramName}' property`);
      }
    });

    const nonStandardParams = data[ALOOMA.ATTRS.NON_STANDARD] ?? {};
    const params = {
      // Required
      [ALOOMA.PARAMS.ELEMENT]: data[ALOOMA.ATTRS.ELEMENT],
      [ALOOMA.PARAMS.SOURCE]: data[ALOOMA.ATTRS.SOURCE],
      // Optional
      [ALOOMA.PARAMS.COUPON_ID]: data[ALOOMA.ATTRS.COUPON_ID],
      [ALOOMA.PARAMS.NOTIFICATION_ID]: data[ALOOMA.ATTRS.NOTIFICATION_ID],
      [ALOOMA.PARAMS.PACKAGE_ID]: data[ALOOMA.ATTRS.PACKAGE_ID],
      [ALOOMA.PARAMS.RETAILER_ID]: data[ALOOMA.ATTRS.RETAILER_ID],
      [ALOOMA.PARAMS.RULE_ID]: data[ALOOMA.ATTRS.RULE_ID],
      [ALOOMA.PARAMS.SECTION]: data[ALOOMA.ATTRS.SECTION],
      [ALOOMA.PARAMS.TAG_ID]: data[ALOOMA.ATTRS.TAG_ID],
      [ALOOMA.PARAMS.TEXT]: data[ALOOMA.ATTRS.TEXT],
      [ALOOMA.PARAMS.LIST_ID]: data[ALOOMA.ATTRS.LIST_ID],
      [ALOOMA.PARAMS.USER_ID]: data[ALOOMA.ATTRS.USER_ID],
      [ALOOMA.PARAMS.MODAL_TYPE]: data[ALOOMA.ATTRS.MODAL_TYPE],
      [ALOOMA.PARAMS.MODAL_NAME]: data[ALOOMA.ATTRS.MODAL_NAME],
      ...nonStandardParams,
    };

    trackClickEvent(params);
  } catch (error) {
    console.error(error);
  }
};

export const sendPageViewAloomaEventForLp = lpTest => {
  return `
    function sendPageViewAloomaEventForLp() {
      if (!window.alooma) return;
      
      function getGaClientId() {
        const gaCookie = document.cookie.split(';').find(cookie => cookie.includes("${COOKIES.gaClientId}"));
        
        return gaCookie?.substring(6);
      }

      function isAndroid() {
        return navigator?.userAgent ? /Android/i.test(navigator.userAgent) : false;
      }

      function isIOS() {
        return navigator?.userAgent ? /iPad|iPhone|iPod/.test(navigator.userAgent) : false;
      }

      function isTablet() {
        if (!navigator?.userAgent) return false;

        return ${TABLE_REGEX}.test(navigator.userAgent.toLowerCase()) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      }

      function isMobile() {
        return (isAndroid() || isIOS()) && !isTablet();
      }

      const getOrigin = () => {
        if (isMobile()) {
          return "${ALOOMA.ORIGIN.MOBILE}";
        }
      
        if (!isTablet()) {
          return "${ALOOMA.ORIGIN.DESKTOP}";
        }
      
        return "${ALOOMA.ORIGIN.TABLET}";
      };

      const data = {
        source_page: "${ALOOMA.SOURCE_PAGES.LANDING_LP}",
        lp_test: "${lpTest}",
        source: getOrigin(),
        browser_url: window.location.href,
        site_version: "${ALOOMA.VERSION}",
        ga4_client_id: getGaClientId(),
      };

      window.alooma.track("${ALOOMA.EVENT_NAMES.PAGE_VIEW}", data);
    }
  `;
};

// ! Service
const Alooma = {
  // Contexts
  CouponIdContext,
  NotificationIdIdContext,
  PackageIdContext,
  RetailerIdContext,
  RuleIdContext,
  SectionContext,
  SourceContext,
  ModalTypeContext,
  ModalNameContext,
  TagIdContext,
  NonStandardDataContext,

  // Hooks (common)
  useContextDataAttrs,

  // Hooks (trackers)
  useClicksTracker,
  usePageViewTracker,
  useImpressionTracker,

  // ! Methods
  /**
   * ! A special method to forced track click events inside selects from the react-select library.
   * ! Don't use it in other cases.
   */
  forcedTrackClickEvent,
  sendWebVitalsReport,

  // ! NOTE: Allowed to use only in legacy code
  // TODO: Remove after freeing from the legacy code
  UNSAFE_trackAloomaEvent,
  UNSAFE_trackPageViewEvent: trackPageViewEvent,

  formRegistrationData,
};

export default Alooma;
