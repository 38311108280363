/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'In evidenza',
  popular: 'Popolari',
  couponsAndDeals: 'Coupon e offerte',
  yourFavorites: 'I tuoi preferiti',
  coupons: 'Coupon',
  headerCoupons: 'Karma | Coupon',
  lastSaved: 'Ultimi salvati',
  favorites: 'Preferiti',
  trending: 'Di tendenza',
  weAreLoving: 'Ci piace',
  karmaHome: 'Karma | Home',
  goodMorning: 'Buongiorno',
  goodAfternoon: 'Buon pomeriggio',
  goodEvening: 'Buonasera',
  addFavoriteStores: 'Aggiungi i negozi preferiti',
  nothingFound: 'Nessun risultato',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'Salva impostazioni',
  enableBrand: 'Abilita {{name}}',
  chrome: 'Chrome',
  edge: 'Edge',
  firefox: 'Firefox',
  ie: 'Internet Explorer',
  oldEdge: 'Edge',
  opera: 'Opera',
  safari: 'Safari',
  remove: 'Rimuovi',
  sponsored: 'Sponsorizzati',
  seeAll: 'Vedi tutto',
  favorite: 'Preferiti',
  onSale: 'In saldo',
  lowInStock: 'Disponibilità limitata',
  backInStock: 'Di nuovo disponibile',
  coupon: 'Coupon',
  soldOut: 'Tutto esaurito',
  fulfilled: 'Soddisfacente',
  deal: 'Offerta',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: 'Ancora {{count, number}}',
  dayRemaining_plural: 'Ancora {{count, number}} giorni',
  karmaStores: 'Negozi Karma Cash',
  priceLeftToWithdraw: 'Ti mancano solo {{value, number}} {{currency}} per prelevare le tue ricompense.',
  fullPrice: '{{value, number}},{{fraction, fraction}} {{currency}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'Denaro in sospeso',
  payouts: 'Pagamenti',
  connectWithPayPal: 'Collega PayPal',
  selectCause: 'Seleziona Causale',
  dayBetweenPayouts: "Puoi prelevare il denaro {{count, number}} giorno dopo la conferma dell'acquisto.",
  dayBetweenPayouts_plural: "Puoi prelevare il denaro {{count, number}} giorni dopo la conferma dell'acquisto.",
  list: 'Elenco:',
  emptyWallet: 'Il tuo portafoglio è vuoto',
  startShoppingToEarn: 'Inizia a fare acquisti per guadagnare ricompense!',
  withdraw: 'Preleva',
  yourDonations: 'Le tue donazioni',
  outOfAmount: 'Su {{value, number}}{{currency}}',
  gettingCloser: 'Ti stai avvicinando!',
  canWithdrawEarnings: 'Adesso puoi prelevare i tuoi guadagni!',
  workedAt: 'Lavorato il {{date}}',
  copied: 'Copiato!',
  getCoupon: 'Ottieni coupon',
  shopNow: 'Acquista subito',
  karmaLogo: 'Logo Karma',
  stores: 'Negozi',
  myLists: 'Le mie liste',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'Tutto',
  lastWeek: 'Ultima settimana',
  lastMonth: 'Ultimo mese',
  lastThreeMonths: 'Ultimi 3 mesi',
  clickID: 'ID clic:',
  date: 'Data',
  time: 'Ora',
  retailer: 'Rivenditore',
  orderID: 'ID ordine:',
  startShoppingToEarnKarmaCash: 'Inizia subito a fare acquisti per guadagnare Karma Cash,',
  browseStores: 'esplora i negozi',
  doNotHaveClicks: 'Non hai clic in questo arco di tempo.',
  doNotHaveTransactions: 'Non hai transazioni in questo arco di tempo.',
  payPalAlt: 'PayPal',
  payPal: 'PayPal:',
  welcomeBonus: 'Bonus di benvenuto!',
  karmaGives: 'Karma Gives!',
  karmaGivesRefund: 'Rimborso Karma Gives!',
  completed: 'Completato',
  notQualified: 'Non idoneo',
  pending: 'In sospeso',
  cancelled: 'Annullato',
  paid: 'Pagato',
  empty: 'Vuoto',
  expired: 'Scaduto',
  discoverAndShop: 'Scopri e acquista',
  contactUs: 'Contattaci',
  news: 'Novità',
  faq: 'FAQ',
  aboutUs: 'Chi siamo',
  karmaItem: 'Articolo Karma',
  email: 'E-mail',
  congrats: 'Complimenti!',
  congratulations: 'Complimenti!',
  getKarmaButton: 'Ottieni il pulsante Karma!',
  saveThingsToBuy: 'Salva le cose che vuoi comprare dopo con Karma!',
  savedFirstItem: 'Hai salvato il tuo primo articolo!',
  getTheButton: 'Ottieni il pulsante',
  savedFirstItem_plural: 'Hai già salvato qualche articolo!',
  iWantToSeeOther: 'Voglio vedere altri <1>negozi supportati</1>',
  neverMissProducts: 'Non perderti mai i saldi o la nuova disponibilità dei prodotti!',
  shareByEmail: 'Condividi via e-mail',
  whenIFindSmth: 'Quando trovo qualcosa che voglio, lo salvo su Karma!',
  completeSignUp: "Completa l'iscrizione",
  addToList: 'Aggiungi alla lista',
  chat: 'Chat',
  karmaCash: 'Karma Cash',
  clickToSaveItem: 'Clicca per salvare un articolo',
  createListAndPressEnter: 'Crea lista e premi "Invio"',
  confirmDeleteProduct: 'Sei sicuro di voler eliminare questo prodotto?',
  shareOnFacebook: 'Condividi su Facebook',
  selectAll: 'Seleziona tutto',
  selectProp: 'Seleziona un {{name}}',
  delete: 'Elimina',
  fulfill: 'Soddisfa',
  mute: 'Silenzia',
  anyPriceChange: 'Ogni cambiamento di prezzo',
  priceOff: 'Sconto del {{value, number}}',
  notifyWhen: 'Avvisami quando',
  tweetOnTwitter: 'Posta su Twitter',
  shareProduct: 'Condividi prodotto',
  markAsFulfilled: 'Contrassegna come soddisfacente',
  unmuteNotifications: 'Riattiva le notifiche',
  muteNotifications: 'Silenzia le notifiche',
  priceEvolution: 'Evoluzione prezzo',
  logOut: 'Esci',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'Informativa privacy',
  confirmDelete: 'Sei sicuro di voler eliminare questo prodotto?',
  termsAndConditions: 'Termini e condizioni',
  logosProvidedBy: 'Loghi forniti da {{name}}',
  copyright: 'Copyright © {{year, clearNumber}} Karma Shopping Ltd., operante come Karma. Tutti i diritti riservati.',
  noNotifications: 'Nessuna notifica',
  clearAllNotifications: 'Cancella tutte le notifiche',
  areYouSureRemoveNotifications: 'Sei sicuro di voler eliminare tutte le notifiche?',
  deleteProducts: 'Elimina prodotti',
  no: 'No',
  yes: 'Sì',
  headerStores: 'Karma | Negozi',
  clearNotification: 'Cancella notifica',
  clear: 'Cancella',
  bell: 'suoneria',
  remindInMinute: 'Ricordamelo tra {{value, number}} minuto',
  remindInMinute_plural: 'Ricordamelo tra {{value, number}} minuti',
  remindInHour: 'Ricordamelo tra {{value, number}} ora',
  remindInHour_plural: 'Ricordamelo tra {{value, number}} ore',
  retailers: 'Rivenditori',
  claimKarmaCash: 'Riscuoti fino al {{percent, number}}% in Karma Cash',
  clickOnClaimButton: 'Clicca sul pulsante "Riscuoti fino al {{percent, number}}% in Karma Cash".',
  goShopping: 'Fai acquisti',
  rackUpCash: 'Accumula denaro',
  getPaidDirectly: 'Fatti pagare direttamente',
  earnRealMoney: 'Guadagna soldi veri su praticamente tutti gli acquisti.',
  trackYourEarnings: 'Traccia i tuoi guadagni e riscuoti a ogni stagione.',
  ordinalNumber: '{{value, fraction}}',
  relatedStores: 'Negozi collegati',
  defaultWhatHappensNext:
    "Per guadagnare Karma Cash automaticamente con il prossimo acquisto, clicca su uno dei nostri negozi Karma Cash aderenti, dove potrai continuare a fare acquisti come al solito! Ricorda: potrai guadagnare Karma Cash soltanto visitando i negozi aderenti, attraverso l'app o il pannello di controllo Karma (incluse le notifiche push), oppure interagendo con le estensioni del browser di Karma su dispositivi fissi o mobili prima dell'acquisto. Una volta che il rivenditore avrà verificato il tuo acquisto, Karma confermerà i tuoi guadagni nel relativo portafoglio.",
  defaultTermsAndConditions:
    "Karma Cash non è disponibile per l'acquisto di buoni regalo o per saldare una qualsiasi quota di un acquisto pagato con buoni regalo, crediti d'acquisto, punti fedeltà o altri programmi a punti. L'uso di codici promozionali non elencati sul sito web di Karma potrebbe invalidare il Karma Cash. Limitazioni aggiuntive possono essere applicate a prodotti o categorie specifici.",
  whatHappensNext: 'E poi che succede?',
  about: 'Informazioni',
  readMore: 'Continua a leggere',
  frequentlyAskedQuestions: 'Domande frequenti',
  loadMore: 'Carica altro',
  questionButton: 'pulsante domanda',
  headerKarmaCash: 'Karma | Ricompense Cash',
  recommended: 'Consigliati',
  highToLow: 'Prezzo decrescente',
  lowToHigh: 'Prezzo crescente',
  sortBy: 'Ordina per:',
  exploreStores: 'Esplora negozi',
  weHaveNotFoundStores: 'Non abbiamo trovato negozi corrispondenti alle tue richieste',
  weHaveNotFoundCoupons: 'Non abbiamo trovato coupon corrispondenti alle tue richieste',
  headerApps: 'Karma | App',
  karmaApps: 'Ottieni un buon karma, ovunque.',
  stayUpdated:
    'Resta aggiornato su tutti i tuoi dispositivi. Il nostro assistente agli acquisti digitale ti permette di salvare articoli da oltre {{number, number}}mila negozi.',
  getFaster: "Ottieni un'esperienza più veloce e fluida scaricando Karma sul tuo Mac.",
  headerProfile: 'Karma | Profilo',
  headerWallet: 'Karma | Portafoglio',
  totalBalance: 'Saldo totale',
  editYourAccount: 'Modifica il tuo account',
  emailAddress: 'Indirizzo e-mail',
  enterEmailAddress: 'Inserisci indirizzo e-mail',
  enterEmail: 'Inserisci e-mail',
  enterEmailLower: 'Inserisci e-mail',
  phoneNumber: 'Numero di telefono',
  enterPhoneNumber: 'Inserisci numero di telefono',
  fullName: 'Nome e cognome',
  enterFullName: 'Inserisci nome e cognome',
  enterPlaceholder: 'Inserisci {{name}}',
  birthday: 'Data di nascita',
  year: 'Anno',
  month: 'Mese',
  day: 'Giorno',
  gender: 'Sesso',
  language: 'Lingua',
  currency: 'Valuta',
  multiCurrency: 'Multi-valuta',
  male: 'Maschio',
  female: 'Femmina',
  notWantShare: 'Non voglio indicarlo',
  mobilePush: 'Push mobile',
  webPush: 'Push web',
  disconnectFromPayPal: 'Disconnettiti da PayPal',
  loginAsUser: 'FUT',
  selectYearAndMonth: 'Seleziona prima anno e mese',
  notSureWhereToStart: 'Non sai da dove iniziare?',
  youDidntSaveItems: 'Non hai ancora salvato alcun articolo.',
  searchItems: 'Cerca articoli',
  headerDashboard: 'Karma | Dashboard',
  headerSales: 'Karma | Saldi',
  couldNotFindStores: 'Ops, non abbiamo trovato saldi',
  karmaGivesValue: '{{value, number}} $',
  karmaGivesModalTitle: 'Ecco qui {{value, number}} $ GRATUITI per iniziare',
  hereIsABitOfGoodKarma: "Ecco un po' di buon karma per esserti registrato con noi!",
  collectAndStartSaving: 'Raccogli e inizia a risparmiare',
  termsAndConditionsAppersant: 'Possono applicarsi',
  mayApply: 'termini e condizioni',
  termsAndConditionsError: "Ti preghiamo di accettare prima le nostre condizioni e l'informativa sulla privacy.",
  validEmailError: "Inserisci un'e-mail valida.",
  completeSignUpCapital: "Completa l'iscrizione",
  or: 'O',
  password: 'Password',
  joinUs: 'Unisciti a noi',
  reviewsPlus: 'Oltre {{amount, number}}mila recensioni',
  logIn: 'Accedi',
  termsAgreement: "Iscrivendoti, accetti le nostre <1>condizioni</1> e l'<3>informativa sulla privacy</3>",
  termsAgreementWithCheckbox: "Accetto le <1>condizioni contrattuali</1> e l'<3>informativa sulla privacy</3>",
  createANewList: 'Crea una nuova lista',
  listCreateBlickError: 'Il nome della lista non può essere vuoto',
  enterListName: 'Inserisci nome lista',
  listDescriptionOptional: 'Descrizione lista (facoltativo)',
  listName: 'Nome lista',
  enterDescriptionForList: 'Inserisci la descrizione della tua lista',
  save: 'Salva',
  deleteList: 'Elimina lista',
  areYouSureRemoveList: 'Sei sicuro di voler rimuovere questa lista?',
  editList: 'Modifica lista',
  listNameCanNotBeBlanck: 'Il nome della lista non può essere vuoto',
  shareList: 'Condividi lista',
  notAMemberCreateAccount: 'Non sei membro? <1>Crea un account!</1>',
  backToLogin: 'Torna al login',
  enterYourEmail: 'Inserisci il tuo indirizzo e-mail',
  resetPassword: 'Reimposta password',
  forgotPasswordMessage: "Riceverai un'e-mail con le istruzioni su come ripristinare la tua password tra pochi minuti.",
  forgotPassword: 'Password dimenticata?',
  reCaptchaMessage:
    "Questo sito è protetto da reCAPTCHA e si applicano l'<1>Informativa sulla privacy</1> e le <3>Condizioni di servizio</3> di Google.",
  pleaseScan: 'Scansiona',
  saveAnItem: 'Salva un articolo',
  areYouSureFulfilled: 'Sei sicuro di voler contrassegnare questi prodotti come soddisfacenti?',
  muteTagDesc: 'Sei sicuro di voler eliminare questi prodotti?',
  muteTagTitle: 'Silenzia le notifiche',
  ok: 'OK',
  canAccessEarningsInPayPal: 'Potrai accedere ai tuoi guadagni dal tuo conto PayPal',
  ccpaTitle:
    'California Consumer Privacy Act (CCPA), e successive modifiche da parte del California Privacy Rights Act (CPRA)',
  ccpaText: ' Inviando questo modulo, esercito il mio diritto di recedere da ogni vendita dei miei dati personali.',
  submit: 'Invia',
  joinKarmaAndGetRewards: 'Unisciti a Karma e ricevi {{stake, number}} ricompense per {{name}}',
  doNotMissOut: 'Non perderteli. Potresti riuscire a risparmiare sul tuo articolo!',
  signUpAndGetMoney: 'Iscriviti e ricevi {{value, number}} $',
  itemAlert: 'Hai un avviso per un articolo!',
  accessKarmaCash: 'Accedi al tuo Karma Cash',
  youEarnedKarmaCash: 'Hai appena guadagnato Karma Cash!',
  verifyYourPaypalAccount: 'Verifica il tuo conto Paypal<1/>',
  weHaveSentAnEmailTo: "Abbiamo inviato un'e-mail a",
  'Fall Favorites': "Preferiti dell'autunno",
  'Karma Cash': 'Karma Cash',
  'Clothing & Luxury': 'Abbigliamento e lusso',
  'Home Decor & Furniture': 'Arredamento e mobili',
  'Electronics & Office Supplies': 'Elettronica e forniture ufficio',
  Luxury: 'Lusso',
  Clothing: 'Abbigliamento ',
  'Handbags & Shoes': 'Borse e scarpe',
  'Jewelry & Watches': 'Gioielli e orologi',
  Accessories: 'Accessori',
  'Beauty Essentials': 'Bellezza',
  'Health & Wellness': 'Salute e benessere',
  Lifestyle: 'Stile di vita',
  'Sports & Outdoors': "Sport e attività all'aperto",
  Travel: 'Viaggi',
  'Electronics & Office supplies': 'Elettronica e forniture ufficio',
  'Entertainment & Online Services': 'Intrattenimento e servizi online',
  Education: 'Istruzione',
  'Finance & FinTech': 'Finanza e FinTech',
  'Pet Supplies': 'Forniture per animali',
  'Food & Beverages': 'Cibo e bevande',
  Men: 'Uomo',
  Women: 'Donna',
  Unisex: 'Unisex',
  Kids: 'Ragazzi',
  Baby: 'Bambini',
  notifications: 'Notifiche',
  changesSaved: 'Cambiamenti salvati',
  headerRetailer: 'Karma | Rivenditore',
  headerRedirect: 'Karma | Reindirizzamento...',
  karma: 'Karma',
  goBackToKarma: 'Torna a Karma',
  authentication: 'Autenticazione',
  authSuccess: "Complimenti! Hai appena effettuato l'accesso correttamente.",
  authFailure: 'Ops, non siamo riusciti ad autorizzarti. Riprova più tardi.',
  mayNotQualify: 'Alcuni prodotti potrebbero non essere idonei per il Karma Cash',
  checkOutRetailers: 'Controlla i rivenditori Karma Cash supportati.',
  headerHowItWorks: 'Karma | Come funziona',
  accept: 'Accetta',
  headerError: 'Karma | Errore',
  pageNotFound: 'Pagina non trovata',
  pageDoesNotExist: 'Ops, sembra che questa pagina non esista.',
  backHome: 'Torna alla Home',
  startShopping: 'Inizia lo shopping',
  illustration: 'Informazioni',
  dashboard: 'Dashboard',
  allRightsReserved: 'Tutti i diritti riservati.',
  product: 'Prodotto',
  disclaimer:
    'Karma Shopping Ltd., operante come Karma. Karma non ha, né pretende di avere, la proprietà dei marchi di terzi. I marchi di terzi che sono visibili o accessibili, direttamente o indirettamente, attraverso la piattaforma Karma, non hanno necessariamente una relazione con Karma, con terzi associati a Karma o con i fornitori di servizi di Karma.',
  help: 'Aiuto',
  team: 'Team',
  howItWorks: 'Come funziona',
  supportedStores: 'Negozi supportati',
  company: 'Azienda',
  planYourPurchase: 'Programma il prossimo acquisto con la nostra app gratuita!',
  itSitsInBrowser: 'Si trova nel tuo browser, mentre esplori tutti i tuoi negozi preferiti.',
  seeSmthYouLike:
    "Vedi qualcosa che ti piace? Clicca sul pulsante per salvarlo nella tua lista e Karma terrà d'occhio per te prezzo e disponibilità.",
  youWillGetNotified:
    'Sarai subito avvisato quando gli articoli che hai salvato saranno in saldo, caleranno di prezzo o torneranno disponibili taglia e nel colore che preferisci.',
  everythingYouWant:
    'Tutto ciò che vuoi comprare sarà comodamente in un unico posto, così sarai più organizzato e non dovrai destreggiarti tra tante liste di desideri.',
  headerContactUs: 'Karma | Contattaci',
  needToGetInTouch: 'Hai bisogno di contattarci?',
  dropALine: 'Scrivici',
  comeHangOut: 'Fai un giro sui nostri social',
  joinOurFacebookCommunity: 'Unisciti al nostro gruppo Facebook',
  followOnInstagram: 'Seguici su Instagram',
  headerAboutUs: 'Karma | Chi siamo',
  addTheItems:
    "Aggiungi gli articoli che vuoi acquistare - puoi personalizzare anche taglia e colore - e l'app ti avviserà quando saranno in saldo.",
  jonathanSay:
    "Jonathan: I rivenditori hanno sfruttato l'I.A. per anni, ma ora sono i consumatori a trarre vantaggio dall'efficienza dell'apprendimento automatico e degli strumenti di automazione.",
  justDownload:
    'Scarica un \'pulsante e cliccaci sopra quando sei su un articolo che ti piace per salvarlo nella tua lista. Su mobile, puoi "condividere" gli articoli direttamente nelle stesse liste.',
  ifYouFind:
    'Se online trovi un articolo che ti piace ma supera il tuo budget, puoi impostare un avviso di saldo, così sarai avvisato quando il prezzo scenderà.',
  onlyAHandful:
    "Sono poche le aziende che offrono avvisi di saldo ai membri online ancora in attività, e tra queste c'è Karma.",
  alwaysBuy: 'Compra sempre qualcosa in saldo con Karma.',
  headerCareers: 'Karma | Carriere',
  mentioningKarma: 'Hai menzionato Karma?',
  getInTouch: 'Mettiti in contatto con noi:',
  shop: 'Acquista',
  weCouldNotFindResults: 'Non abbiamo trovato risultati per "{{name}}"',
  couldNotFindResults: 'Non abbiamo trovato risultati',
  shopAllStores: 'Acquista in tutti i negozi supportati',
  popularStores: 'Negozi popolari',
  shops: 'Negozi',
  headerDiscoverAndShop: 'Karma | Scopri e acquista gli ultimi articoli salvati',
  prev: 'Prec',
  next: 'Succ',
  headerDemo: 'Karma | Demo',
  getTheKarmaButton: 'Ottieni il pulsante Karma',
  installedKarma: 'Hai installato correttamente Karma!',
  settingUpAccount: 'Ora, cominciamo a creare il tuo account.',
  signUpNow: 'Iscriviti subito',
  logInNow: 'Accedi subito',
  signUpLater: 'Iscriviti più tardi',
  wePartnerWith: 'Collaboriamo con oltre {{number, number}}mila negozi online!',
  pickStoresToSee: 'Scegli uno dei negozi qui sotto per vedere come funziona:',
  leaveKarma: "Attenzione: lascerai Karma e andrai alla pagina del negozio per godere dell'esperienza completa.",
  searchFavoriteStores: 'Cerca nei tuoi negozi preferiti',
  clickBelowToStart: 'Clicca qui sotto per iniziare',
  chooseOneOfTheOptions: 'Scegli una delle opzioni qui sotto',
  clickToBegin: 'Clicca sulla barra di ricerca per iniziare',
  preferNotToSay: 'Preferisco non dirlo',
  done: 'Fatto',
  headerUnsubscribe: 'Karma | Annulla iscrizione',
  changeMind: 'Hai cambiato idea?',
  resubscribeList: 'Reiscriviti alla tua mailing list.',
  resubscribeList_plural: 'Reiscriviti alla tua mailing list qui.',
  changePreferencesAtAnyTime: 'Puoi cambiare le tue preferenze in qualsiasi momento nelle <1>impostazioni account.</1>',
  preferencesUpdated: 'Abbiamo aggiornato le tue preferenze sulle e-mail.',
  sorryToSeeYouGo: 'Ci dispiace che tu te ne vada!',
  reSubscribed: 'Sei stato reiscritto',
  allowHours: 'Attendi fino a {{hours, number}} ore perché le modifiche abbiano effetto.',
  missWhenYouGone: 'Ci mancherai! Attendi fino a {{hours, number}} ore perché le modifiche abbiano effetto.',
  headerDisablingSubscribe: 'Karma | Disattivazione iscrizione',
  noLongerAlerts: 'Non riceverai più avvisi per questo articolo.',
  turnAlertsBack: 'Riattiva gli avvisi.',
  toQualify:
    "* Per aver diritto al bonus di benvenuto, devi essere un nuovo membro e fare un minimo di acquisti pari ad almeno trenta dollari (${{dollars, number}}) entro novanta ({{days, number}}) giorni dall'iscrizione.",
  moveAnimations: 'Sposta animazione pulsante',
  headerExtensionDeleted: 'Karma | Estensione eliminata',
  areSorryToSeeYouGo: 'Ci dispiace che tu te ne vada!',
  youHaveGotInYourWallet: 'Hai {{value}} $ nel tuo portafoglio.',
  toWithdraw: ' per prelevare i tuoi guadagni!',
  toContinue: ' per continuare a guadagnare ricompense!',
  reasonUninstalled: 'Facci sapere perché hai disinstallato il pulsante:',
  getInTouchBtn: 'Contattaci',
  howWeCanIncrease: 'Ecco come puoi aumentare la consapevolezza e le vendite con la pubblicità su Karma.',
  helpingShoppers:
    'Aiutare i clienti a scoprire marche che ameranno per anni è una delle nostre maggiori priorità come assistenti personali agli acquisti.',
  whyWeOfferBrands:
    "Ecco perché offriamo ai marchi che ottengono regolarmente un'elevata soddisfazione dei clienti diversi modi per connettersi al nostro pubblico. Raggiungerai gli obiettivi delle tue campagne e il nostro pubblico conoscerà la sua nuova marca preferita.",
  winWin:
    "Diamo ai marchi che ottengono regolarmente un'elevata soddisfazione dei clienti diversi modi per connettersi al nostro pubblico. Raggiungerai gli obiettivi delle tue campagne e il nostro pubblico conoscerà la sua nuova marca preferita.",
  getFeaturedRight: "Promuovi direttamente nella dashboard dell'app o dell'estensione browser",
  getFeaturedDashboard: 'Promuovi nella dashboard di Karma',
  putYourHand:
    'Metti i tuoi prodotti e le tue promozioni proprio dove vengono gli utenti quando sono pronti a comprare. Possiamo creare una varietà di contenuti personalizzati per il tuo marchio, che indirizzeranno un buon traffico dove ti serve.',
  exactPlace: 'Metti il tuo marchio proprio dove vengono gli utenti quando sono pronti a comprare.',
  dedicatedInAppPlacement: 'Inserimento in-app dedicato',
  dashboardFeaturedBanners: 'Banner nella dashboard',
  dashboardPopUpMessages: 'Messaggi pop-up nella dashboard',
  customEmailsAndNewsLetters:
    'Inserimento in e-mail personalizzate e newsletter a oltre {{subscribers, number}} iscritti',
  customEmails: 'E-mail personalizzate a oltre {{subscribers, number}} iscritti',
  getMoreEyes:
    'Ottieni maggiore visibilità sulla tua ultima offerta, codice coupon o linea di prodotti con un inserimento in e-mail dedicate o nella newsletter per i clienti.',
  putYourBrand: 'Metti il tuo marchio proprio dove vengono gli utenti quando sono pronti a comprare.',
  newsletterInclusion: 'Inserimento nella newsletter',
  dedicatedCustomEmails: '{{percent, number}}% e-mail dedicate personalizzate',
  bannerPlacementInEmails: 'Inserimento banner nelle e-mail di notifica',
  pushNotificationsToShoppers: 'Notifiche push a oltre {{shoppers, number}} clienti',
  pushNotificationsGiveTouchPoint:
    'Le notifiche push danno alla tua campagna un altro punto di contatto e offrono elevati open rate e click rate.',
  featureBrandClients: 'Marchi clienti pubblicizzati',
  growingPerMonth: 'Crescita di oltre {{number, number}}mila al mese',
  members: 'Membri',
  percent: '{{value, number}} $',
  kindOfReachYouExpect: 'Che tipo di seguito puoi aspettarti?',
  ourAudience: 'Il nostro pubblico',
  tabIntoNewSource: 'Sfrutta una nuova fonte di clienti qualificati per la tua prossima campagna',
  tellUsAboutYourCompany:
    "Parlaci un po' della tua azienda e dei tuoi obiettivi e noi ti mostreremo come possiamo aiutarti a superarli.",
  openAndTap: 'Apri Safari e vai alla pagina del prodotto che vorresti salvare, quindi tocca il pulsante "Condividi".',
  shareIllustration: 'Condividi illustrazione',
  tapMore: 'Tocca il pulsante "Altro" sulla destra del pannello.',
  moreIllustration: 'Altre illustrazioni',
  enableIllustration: 'Abilita illustrazioni',
  tapEdit: 'Tocca il pulsante "Modifica" nella parte superiore del pannello.',
  enableApp: 'Abilita Karma e tocca il pulsante "aggiungi" per aggiungerlo ai preferiti, quindi tocca "Fatto".',
  goBackToTheProduct:
    'Torna alla pagina del prodotto, tocca di nuovo sul pulsante "Condividi", quindi tocca il pulsante "Karma" per salvare il tuo prodotto.',
  recommendedBy: 'Consigliato da',
  ourMembersSavingMoney: 'I nostri membri ❤️ risparmiare con Karma. Ecco perché.',
  ourMembersSavingMoney2: 'I nostri membri <1/> risparmiare con Karma. Ecco perché.',
  addToBrowser: 'Aggiungi a {{browser}}, è gratis',
  planPurchases: 'Programma i tuoi prossimi acquisti',
  karmaMakesItEasy: 'Karma rende semplice organizzare i tuoi prossimi acquisti in un unico posto.',
  buyAtRightTime: 'Compra al momento giusto',
  automaticallyAlert: 'Karma ti avvisa automaticamente quando è il momento giusto per comprare.',
  cashOnVacation: 'La tua prossima vacanza con Karma Cash',
  karmaPartners: 'Karma collabora con molti siti di viaggi e hotel.',
  headerRewards: 'Karma | Migliori coupon e ricompense in denaro',
  mission: 'La nostra missione',
  empowerYou: 'La nostra missione? Permetterti di fare le scelte giuste al momento giusto quando compri online.',
  weAreSeriousAboutPrivacy: 'Siamo seri in fatto di privacy e sicurezza',
  weDoNotSellData: 'Non vendiamo i tuoi dati',
  karmaIsSerious: 'Karma è molto seria per quanto riguarda i tuoi dati e non li venderemo mai a terzi. Mai.',
  karmaIsSecure: 'Karma è sicura',
  weUseBankSecurity:
    'Utilizziamo una sicurezza di livello bancario (crittografia a {{bit, number}} bit) per fare in modo che i tuoi dati siano al sicuro.',
  helpsSaveMore: 'Karma ti aiuta a risparmiare di più!',
  alertMe: 'Avvisami',
  buyWithKarmaEarn: 'Compra con Karma - Guadagna {{number, number}} $',
  productSaved:
    'Complimenti! Questo prodotto è stato salvato nella tua lista di articoli. Sarai avvisato quando il prezzo scende.',
  willApplyTheBestCoupons: 'Karma applicherà automaticamente i migliori coupon al tuo acquisto!',
  downloadAndAutoApply: 'Scarica Karma e applica automaticamente',
  whatIsKarma: "Cos'è Karma?",
  claimUpToRewards: 'Riscuoti ricompense in denaro fino al {{number, number}}%',
  youMightAlsoLike: 'Potrebbero anche piacerti',
  weAreSorry: 'Ci dispiace, ma qualcosa è andato storto ed è impossibile visualizzare la pagina.',
  tryToReloadPage: 'Prova a ricaricare la pagina.',
  Color: 'Colore',
  Size: 'Taglia',
  latestCoupons: '{{name}} | Ultimi coupon e cashback di Karma {{year, clearNumber}}',
  neverPayFullPrice: 'Non pagare mai a prezzo pieno. Ottieni i coupon e il cashback di Karma per {{name}}',
  neverPayFullPriceForTag: 'Non pagare mai a prezzo pieno per {{name}}',
  saveMoneyWhenShoppingAt: 'Risparmia quando acquisti da {{name}}. Iscriviti a Karma gratuitamente',
  saveMoneyWhenShoppingFor: 'Risparmia quando acquisti {{name}}. Iscriviti a Karma gratuitamente',
  tagByBrand: 'Karma | {{tag}} da {{brand}}',
  typeToSearch: 'Digita per cercare',
  getUSDWhenJoinToday: 'Riceverai {{value, number}} $ se ti iscrivi a Karma oggi!',
  buyProduct: 'Compra prodotto',
  userWishList: 'Karma | {{name}} lista dei desideri',
  byWhom: 'di',
  copiedToClipboard: 'Copiato negli appunti!',
  send: 'Invia',
  cause: 'Seleziona causa:',
  aCause: 'Seleziona una causa:',
  close: 'Chiudi',
  selectThisCause: 'Seleziona questa causa:',
  habitatForHumanity:
    "{{name}} s'impegna a ridurre la povertà a lungo termine attraverso alloggi sociali a prezzi accessibili; la loro missione è costruire un ambiente sicuro in cui le famiglie e le comunità possano prosperare",
  casaDescription:
    "La National CASA/GAL Association sostiene e promuove la difesa dei volontari nominati dal tribunale, in modo che ogni bambino che ha subito abusi o abbandono possa essere al sicuro, avere una casa permanente e l'opportunità di prosperare",
  endHomelessnessDescription:
    "La National Alliance to {{name}} è un'organizzazione apartitica impegnata a prevenire e porre fine al problema dei senzatetto negli Stati Uniti",
  pathDescription: 'PATH sta mettendo fine al problema dei senzatetto per relativo a persone, famiglie e comunità',
  feedingAmericaDescription: 'Feeding America è la più grande organizzazione nazionale contro la fame',
  mealsOnWheelsDescription:
    '{{name}} consente ai programmi della comunità locale di migliorare la salute e la qualità della vita degli anziani che servono, in modo che nessuno rimanga affamato o isolato',
  actionAgainstHunger:
    "{{name}} è un'organizzazione umanitaria globale che intraprende azioni decisive contro le cause e gli effetti della fame",
  supportMedicalAdvancement: 'Sostieni il progresso medico',
  saveChildsHeartDescription:
    "Save A Child's Heart cura problemi cardiaci indipendentemente da razza, religione, sesso, nazionalità o stato finanziario",
  su2c: 'Stand Up To Cancer (SU2C) finanzia e sviluppa i trattamenti contro il cancro più nuovi e promettenti per aiutare i pazienti oggi stesso',
  drWithoutBorders: '{{name}} è un movimento globale indipendente che fornisce assistenza medica dove è più necessario',
  activeMinds:
    "{{name}} è la principale organizzazione senza scopo di lucro della nazione che sostiene la sensibilizzazione e l'educazione alla salute mentale per i giovani adulti",
  cleanAirTaskForceDesc:
    '{{name}} promuove il cambiamento nelle tecnologie e nelle politiche necessarie per raggiungere un pianeta a zero emissioni e ad alta energia a un costo accessibile',
  wwfDesc:
    'La missione di {{name}} è preservare la natura e ridurre le minacce più pressanti alla diversità della vita sulla Terra',
  waterkeepsDesc:
    "{{name}} lotta per un'acqua pulita. Waterkeeper Alliance lavora per garantire che ogni comunità in tutto il mondo abbia acqua potabile, pescabile e balneabile",
  xprice:
    'Ogni singolo giorno XPrize lavora per creare un mondo migliore in cui tutti abbiano accesso ad acqua pulita, cibo nutriente, alloggi a prezzi accessibili, apprendimento efficace, cure mediche di alto livello ed energia abbondante e non inquinante',
  globalActDesc: '{{name}} lavora per porre fine allo sfruttamento sessuale e commerciale',
  pcaa: '{{name}} lavora duramente per realizzare la nostra visione di un mondo in cui tutti i bambini crescano felici, sani e preparati ad avere successo in famiglie e comunità solidali',
  endCAN:
    "EndCAN aumenta la consapevolezza degli impatti degli abusi sulla salute mentale e pubblica, con la missione di porre fine all'abuso e all'abbandono dei minori",
  futuresDesc:
    'FUTURES offre programmi, politiche e campagne innovativi che danno potere a individui e organizzazioni che lavorano per porre fine alla violenza contro donne e bambini in tutto il mondo',
  thornDesc: '{{name} sviluppa tecnologia per difendere i bambini dagli abusi sessuali.',
  'Eradicate Homelessness': 'Sradicare il problema dei senzatetto',
  'Help End Hunger': 'Aiuta a porre fine alla fame',
  'Support Medical Advancement': 'Sostieni il progresso medico',
  'Combat Climate Change': 'Combatti il cambiamento climatico',
  'Act To End Abuse': 'Agisci per porre fine agli abusi',
  itemsYouSaved: 'Elementi che hai salvato per dopo',
  beta: 'BETA',
  alsoAvailableAt: 'Disponibile anche su',
  atName: 'su {{name}}',
  bestPrice: 'Miglior prezzo',
  passwordWasSuccessfullyReset: 'La password è stata reimpostata con successo',
  paidWith: 'Pagato con',
  infoFirstNumber: 'Oltre {{number, number}}{{unit}}',
  infoFirstTitle: 'Membri',
  infoSecondNumber: 'Oltre {{number, number}}{{unit}}',
  infoSecondTitle: 'Visite mensili',
  infoThirdNumber: 'Oltre {{number, number}}{{unit}}',
  infoThirdTitle: 'Elenchi di e-mail',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'Di millennial',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'Di donne',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'Situati negli Stati Uniti',
  infoSeventhNumber: 'Oltre {{number, number}}{{unit}}',
  infoSeventhTitle: 'Notifiche push mobili attivabili',
  infoEighthNumber: 'Oltre {{number, number}}{{unit}}',
  infoNinthPreNumber: '',
  infoNinthNumber: 'Oltre {{number, number}}{{unit}}$',
  infoNinthTitle: 'Verranno spesi dagli utenti entro la fine del {{year, clearNumber}}',
  karmaIsAlwaysWithYou: 'Karma è sempre con te quando fai acquisti',
  saveItemsToBuy: 'Salva gli articoli e ti diremo quando acquistare',
  weDoTheWork: 'Facciamo il lavoro per trovarti i migliori coupon',
  earnRealCashThatAdds: 'Guadagna denaro vero che si aggiunge al tuo portafoglio',
  setUpStrongPassword: 'Imposta una password sicura',
  settingEmailAccount:
    "L'ultimo passo per risparmiare con Karma? Iscriviti in modo che possiamo informarti di avvisi importanti sui tuoi articoli, come i cali di prezzo.",
  settingPasswordAccount:
    'La password deve essere formata da un minimo di {{number, number}} caratteri e non deve includere le tue credenziali di posta elettronica.',
  countWaysToUse: '{{number, number}} modi per usare Karma',
  goBack: 'Torna indietro',
  pleaseChooseStrongerPassword: 'Scegli una password più sicura',
  pleaseEnterValidEmailAddress: 'Inserisci un indirizzo e-mail valido',
  illSignUpLater: 'Mi registrerò in seguito',
  enterYourEmailAddress:
    'Inserisci il tuo indirizzo e-mail e noi ti invieremo un link per ripristinare la tua password.',
  headerOnboardingIosExtension: 'Karma | Avviamento estensione per iOS',
  weWillAutomaticallyApplyCouponsWhereverYouShop: 'Ovunque farai acquisti, noi applicheremo automaticamente i coupon.',
  continue: 'Continua',
  changeLanguage: 'Cambia lingua',
  selectedLanguage: 'Seleziona lingua',
  headerMuteItem: 'Karma | Silenzia articolo',
  itemHasBeenMuted: 'Fatto! Questo articolo è stato silenziato.',
  toUnmuteItem: 'Per desilenziare questo articolo:',
  headToMyItems: 'Vai alla scheda "I miei articoli".',
  clickThreeButtonsInItem: "Fai clic sui tre pulsanti nell'angolo in basso a destra dell'elemento",
  clickUnmute: 'Clicca su ‘Desilenzia’',
  weveJustRaisedInFunding:
    'Abbiamo raccolto ${{value, number}}M con un finanziamento per aiutarti ad acquistare consapevolmente!',
  weveJustRaised: 'Abbiamo raccolto ${{value, number}}M',
  landingReview1: 'Ottima app! Ho ricevuto un avviso di diminuzione del prezzo e ho risparmiato un sacco di soldi!',
  landingReview2: 'Mi ha fatto risparmiare {{value, number}} $ sul mio primo acquisto. Incredibile!',
  landingReview3: "È un'app utile; adoro notifiche e ricompense.",
  landingReview4: "Mai provato un'esperienza di shopping più fluida!",
  landingReview5: "Ho risparmiato il {{value, number}}%! Non avrei mai saputo dell'offerta, senza!",
  landingReview6: 'Facile monitorare i prodotti che ami... Consigliatissimo...!',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'Reimposta password',
  repeatPassword: 'Ripeti password',
  continueAsGuest: 'Continua come ospite',
  viewAt: 'Vedi su {{- name}}',
  signUp: 'Registrati',
  youMayAlsoLike: 'Potrebbe piacerti anche',
  buyOnShop: 'Acquista su',
  viewOn: 'Visualizza su {{- name}}',
  quickView: 'Visualizzazione rapida',
  newList: 'Nuovo elenco',
  undo: 'Annulla',
  productDeleted: 'Prodotto eliminato',
  linkCopiedToClipboard: 'link copiato negli appunti',
  wishlists: 'Liste desideri',
  headerPartnerships: 'Karma | Partnership',
  letsJoinForces: 'Uniamo le forze',
  bePartOfTheSmartShoppingRevolution:
    'Partecipa alla rivoluzione dello shopping intelligente. Proporremo il tuo marchio a oltre {{number, number}} milioni di acquirenti pronti a finalizzare un acquisto.',
  seeKarmaInAction: 'Guarda Karma in azione',
  discoverHowKarma:
    "Scopri come IA e soluzioni basate sull'automazione di Karma migliorano l'intero percorso d'acquisto, convogliando traffico mirato sulle tue offerte.",
  stepUpYourSales: 'Aumenta le tue vendite',
  averageROAS: 'ROAS<br/>medio',
  increaseInAOV: "Aumento<br/>dell'AOV",
  useKarmaToPlanTheirNextPurchase: 'Usa Karma per pianificare<br/>il loro prossimo acquisto',
  meetOurShoppers: 'Incontra i nostri acquirenti',
  meetOurShoppersDescription:
    'Sfrutta le nostre soluzioni di marketing orientate al rendimento e conquista gli acquirenti altamente propensi a comprare. Coi nostri posizionamenti multipiattaforma, raggiungi gli utenti ovunque si trovino.',
  audienceInfoFirstNumber: 'Oltre {{number, number}}{{unit}}',
  audienceInfoFirstTitle: 'Utenti',
  audienceInfoSecondNumber: 'Oltre {{number, number}}{{unit}}',
  audienceInfoSecondTitle: 'Visite mensili',
  audienceInfoThirdNumber: 'Oltre {{number, number}}{{unit}}',
  audienceInfoThirdTitle: 'Articoli salvati',
  audienceInfoFourthNumber: '{{value, number}}%',
  audienceInfoFourthTitle: 'Utenti negli USA',
  audienceInfoFifthNumber: '{{value, number}}%',
  audienceInfoFifthTitle: 'Generazione Z e millennial',
  audienceInfoSixthNumber: '{{value, number}}%',
  audienceInfoSixthTitle: 'Acquirenti donne',
  ourUsersLoveUs: 'Gli utenti ci adorano!',
  partnershipsReview1:
    "Un'estensione fantastica! La uso da anni ed è eccezionale per poter salvare tanti prodotti in un unico luogo: rende lo shopping online molto meno stressante!",
  partnershipsReviewAuthor1: 'Millie Robinson',
  partnershipsReview2:
    'Di acquisti online ne faccio uno SPROPOSITO, perciò posso affermare in tutta onestà che Karma è il miglior alleato degli acquirenti per tanti motivi...',
  partnershipsReviewAuthor2: 'Sadie Mae',
  partnershipsReview3:
    "La migliore app di shopping nel Play Store. Facilissima da usare. Tutti gli articoli salvati nei miei negozi preferiti sono in un'unica app! 👍🏻",
  partnershipsReviewAuthor3: 'Kimberly Rogers',
  performanceBasis: 'Basata su rendimenti',
  fixedFee: 'Commissione fissa',
  thanksForSubmitting: "Grazie per l'invio!",
  someoneFromOurPartnershipsTeamWillBeInTouchShortly: 'Un addetto del nostro team di partnership ti contatterà presto.',
  letsKickStartOurPartnership: 'Avviamo la nostra partnership!',
  emailOrPhoneNumber: 'E-mail o numero telefonico',
  retailerName: 'Nome rivenditore',
  affiliateNetwork: "Rete d'affiliazione",
  budgetStructure: 'Struttura budget',
  budget: 'Budget',
  tellUsMore: 'Fornisci ulteriori informazioni',
  pleaseFillInAllRequiredFields: 'Compila tutti i campi obbligatori',
  partnerships: 'Partnership',
  weCannotSaveThisItem: "Non è possibile salvare l'articolo <1/> poiché questo negozio non è supportato",
  theresCoupon: "C'è un coupon!",
  yourItemIsOffWithCode:
    "Per l'articolo c'è uno sconto del {{value, number}}% con il codice {{code}} presso {{- name}}.",
  muteAllNotification: 'Disattiva tutte le notifiche',
  unMuteAllNotification: 'Riattiva tutte notifiche',
  notificationSettings: 'Impostazioni notifiche',
  deleteNotification: 'Elimina notifica',
  notificationDeleted: 'Notifica eliminata',
  itemMuted: 'Articolo disattivato',
  itemUnMuted: 'Articolo riattivato',
  priceDrop: 'Diminuzione del prezzo',
  superDeal: '🔥 Super offerta',
  notSureWhenToBuy: 'Non sai quando acquistare? Crea un account gratuito e ti comunicheremo il momento delle offerte.',
  tcMayApply: 'Potrebbero applicarsi T&C',
  earnKarmaCash: 'Guadagna fino al {{percent, number}}% di Karma Cash.',
  saveToKarma: 'Salva articolo su Karma',
  getKarmaToTrackItem: "Traccia l'articolo con Karma",
  itemSaved: 'Articolo salvato!',
  youCanNowFindItem: "Ora puoi trovare l'articolo sul tuo pannello di controllo Karma.",
  getKarmaExtension: "Ottieni l'estensione Karma per <1/> monitorare questo must-have",
  notifyYou: 'Ti avviseremo quando sarà il momento migliore per acquistare!',
  downloadTheExtension: "Scarica l'estensione",
  outOfStock: 'Esaurito',
  invalidEmailError: 'E-mail non valida',
  collapse: 'Comprimi',
  expand: 'Espandi',
  lists: 'Liste',
  organizeYourItemsIntoWishListsWithJustAClick: 'Organizza gli articoli in liste dei desideri con un clic.',
  newItem: 'Nuovo articolo',
  helpCenter: 'Centro assistenza',
  priceHighToLow: 'Prezzo dal più alto',
  priceLowToHigh: 'Prezzo dal più basso',
  sale: 'Vendita',
  filterBy: 'Filtra per:',
  karmaExclusive: 'Esclusiva K',
  noItemsFound: 'Nessun articolo trovato',
  select: 'Seleziona',
  likeSneakersOrBlackFriday: 'Come «Sneakers» o «Black Friday»',
  updateList: 'Aggiorna elenco',
  firstName: 'Nome',
  lastName: 'Cognome',
  enterFirstName: 'Inserisci il nome',
  enterLastName: 'Inserisci il cognome',
  recentSearches: 'Ricerche recenti',
  addYourFavoriteStores: 'Aggiungi i tuoi negozi preferiti',
  toAccessTheirLatestCoupons: 'per accedere agli ultimi coupon',
  logosProvidedByClearbit: 'Logo forniti da Clearbit',
  dateOfBirth: 'Data di nascita',
  weHaveEmailedYouALinkToResetYourPassword: 'Ti abbiamo inviato un link per reimpostare la password.',
  didNotGetIt: 'Non ho capito',
  profile: 'Profilo',
  promotionsAndActivity: 'Promozioni e attività',
  couponsForFavoriteStores: 'Coupon per i negozi preferiti',
  accountActivity: "Attività dell'account",
  powerShopper: 'Power shopper? 💪',
  enterLinkToAnyItemPage: "Inserisci un link a qualsiasi pagina dell'articolo",
  saveStuffWithZeroFuss: "<0>Ottieni l'estensione del browser</0> e salva i contenuti senza problemi.",
  viewMore: 'Visualizza di più',
  tooHotToMiss: 'Troppo bello per perderlo',

  // WebsiteLandingKarmaOld
  joinShoppers: 'Unisciti a oltre {{number, number}} milioni di clienti',
  waysToUse: 'Modi per usare Karma',
  youCanUseKarma:
    'Puoi usare Karma in molti modi, ad esempio per ottenere il prezzo migliore al momento giusto mentre programmi i prossimi acquisti. Ecco alcuni esempi che ti consigliamo:',
  livesInBrowser: 'Karma è nel tuo browser',
  browserExtension: "L'estensione browser di Karma ti assiste ovunque tu faccia acquisti sul web.",
  helpsYouPlan: 'Ti aiuta a programmare i tuoi prossimi acquisti',
  notReadyToBuyJust:
    "Non sei ancora pronto per comprare? Salvalo per dopo con la nostra estensione per browser e pensaci meglio. Puoi tenere d'occhio tutti gli articoli salvati in un unico posto. ",
  automaticallyFindsCouponCodes: 'Trova automaticamente codici coupon',
  findsAndAppliesCodes:
    'Troviamo e applichiamo automaticamente per te i coupon migliori, in modo che tu possa avere ogni volta il miglior rapporto qualità/prezzo.',
  soYouCanBuyAtTheRightTime: 'Così potrai comprare al momento giusto',
  usesCleverTechnologies:
    'Karma usa la tecnologia intelligente per tracciare il calo dei prezzi e la nuova disponibilità, in modo che tu possa comprare al momento giusto. ',
  notOnlySave:
    'Karma non ti fa solo risparmiare, ti fa anche guadagnare! Niente punti. Niente trucchi. Puoi guadagnare soldi veri ogni volta che acquisti.',
  makeImpact: 'Lascia il segno!',
  donate:
    'Acquista come faresti di solito e guarda come Karma ti restituisce i soldi. Per ogni acquisto superiore ai {{over, number}} $, doniamo {{dollars, number}} $ a una causa di tua scelta.',
  accessToOffers: 'Accedi a offerte esclusive',
  accessItems:
    'Accedi agli articoli salvati in un unico posto, scopri offerte esclusive e scopri cosa fa tendenza nel mondo su Karma.',
  hereIsHowItWorks: 'Ecco come funziona',
  learnMoreAboutGives: 'Scopri di più su Karma Gives',
  alwaysKnowWhenPricesDrop: 'Saprai quando i prezzi scendono',
  getNotifiedWhenBackInStock: 'Ricevi una notifica quando è nuovamente disponibile',
  findsYouBestCoupons: 'Ti trova i migliori coupon',
  getKarmaCashWhenYouShop: 'Ottieni Karma Cash quando compri',
  easilySaveAllThings: 'Risparmia facilmente su tutte le tue cose preferite su oltre {{value, number}}K negozi',
  karmaProvidesGreatTechnology:
    'Karma offre tecnologia e flessibilità per fare acquisti più intelligenti, aiutandoti a evitare acquisti impulsivi non necessari, il tutto mentre risparmi tempo e denaro.',

  accountActivityAndImportantInformation: "Attività dell'account e informazioni importanti",
  addKarmaToYourDesktop:
    "Aggiungi Karma al tuo desktop in modo da poter fare acquisti<br/> durante l'orario di lavoro.",
  addNumberItemsToList: "Aggiungi {{value}} articoli all'elenco",
  addOneItemToList: "Aggiungi 1 articolo all'elenco",
  addStuffYouFindToThisListToKeepItOrganized:
    'Aggiungi le informazioni che trovi a questo elenco per mantenerlo organizzato.',
  alrightBut: 'Va bene, ma ricorda che potresti perderti qualcosa!',
  areYouSureYouWantToDisconnectFromPaypal: 'Sei sicuro di voler disconnetterti da Paypal?',
  balance: 'Saldo',
  buyWhateverYouWant: "Compra quello che vuoi e fai felice l'impiegata della banca.",
  cashIsBack: '<1>Cash</1> è tornato',
  cashIsBackColoredRight: 'Cash è <1>tornato</1>',
  cashOutRewards: 'Premi in contanti',
  checkItOffAsYouBuy: "Controllalo al momento dell'acquisto",
  congratulationsOnCashout: 'Complimenti! Ora puoi incassare i tuoi premi.',
  connectToPayPalToCashOut: 'Collega un conto PayPal per riscuotere i tuoi premi',
  connectYourPaypalAccountToCashOutRewards: 'Collega il tuo conto PayPal per riscuotere i premi.',
  downloadOurApp: 'Scarica la nostra app e porta <1>Karma in viaggio</1>',
  dropSomeShoppingInspo: 'Lascia qualche spunto per lo shopping alla tua famiglia.',
  earnKarmaCashShort: 'Fino al {{percent, number}}% K in contanti',
  earned: 'Guadagnati',
  enterYourPassword: 'Inserisci la tua password',
  firstAddToList: "Innanzitutto, aggiungilo all'elenco",
  followOnLinkedin: 'Seguici su LinkedIn',
  freshOutCoupons: 'Fresco di coupon',
  getBuzzedWhenYourWishes:
    'Lasciati entusiasmare 🐝 quando i tuoi «desideri» diminuiscono di prezzo o tornano disponibili.',
  getIt: 'Ottienilo',
  getTheApp: "Scarica l'app",
  gotIt: 'Ho capito',
  gotStuck: 'Sei rimasto bloccato? Clicca <1>qui</1> per iniziare a muoverti.',
  happyShoppers: '👉 più di {{count, number}} milioni di clienti soddisfatti',
  howElseCanWeImproveKarma: 'In quale altro modo possiamo migliorare il Karma?',
  iNeedThisInMyLife: 'Ne ho bisogno nella mia vita',
  info: 'Informazioni',
  itemUpdatesUnsubscribe: 'Aggiornamenti degli articoli (riduzioni di prezzo, riassortimenti e altro)',
  itsAGhostTown: 'È una città fantasma',
  joinTheTeam: 'Entra nel team',
  karmaGetsYouTheLowestPossiblePrice: 'Karma ti offre il prezzo più basso possibile ogni 👏 singola 👏 volta.',
  karmaIsAnApp: "Karma è un'app e un'estensione che ti aiuta a risparmiare tempo e denaro quando acquisti online.",
  karmaYourFavoriteBrands: '<1>Karma</1> i tuoi marchi preferiti',
  karmaYourFavoriteBrandsMobile: '<1>Karma</1> i tuoi marchi preferiti',
  knowHowToShopBetter: 'Impara a <1>fare acquisti meglio</1> di chiunque altro',
  letsPlay: 'Giochiamo',
  lolWereAVerbNow: '(lol ora siamo un verbo)',
  lookAtYouLittleScroller: 'Guardati, piccolo scrollatore. Complimenti per essere arrivati fin qui.',
  lookAtYouLittleScrollerMobile: 'Guardati, piccolo scrollatore.',
  lookingForInfluencer: 'Cerchi partnership pubblicitarie?',
  markItemsYouveMadeYourOwnItFulfillsSoGood: 'Contrassegna gli articoli che hai creato tu. Soddisfa così bene!',
  markNumberItemsAsFullfilled: 'Stai per contrassegnare {{value}} articoli come evasi',
  meetYouAtIn: 'Ci vediamo al {{retailer}} tra 3 secondi...',
  missOut: 'perdere',
  missingYouAlready: 'Mi manchi già!',
  money: 'soldi',
  never: 'Mai',
  nevermind: 'Non importa',
  noFomoHere:
    'Qui non riuschi di perderti nulla. Ti faremo un fischio quando i tuoi «desideri» diminuiranno di prezzo o torneranno disponibili.',
  noNotificationsYet: 'Sei completamente assorbito ',
  noNotificationsYet2:
    'Non appena si verifica un calo dei prezzi o un aggiornamento del riassortimento, lo troverai qui.',
  nowLetsTalPrivacy: 'Ora parliamo di <1>privacy<1/>',
  nowLetsTalkAboutPrivacy: 'Ora parliamo di privacy 🤫',
  onlineShoppingButBetter: 'Acquisti online ma <1>migliori</1>',
  orCopyTheLinkBelowAndSend: 'Oppure, copia il link qui sotto e invialo a loro in stile vecchia scuola.',
  other: 'Altro',
  passwordValidationNote:
    'Deve contenere almeno 8 caratteri, tra cui una lettera maiuscola, una lettera minuscola e un numero.',
  payLess: 'Paga meno',
  playFavesWithYourItems: 'Gioca ai preferiti con i tuoi oggetti',
  preparingYourKarmaCash: 'Preparo il tuo Karma Cash.',
  savingMoneyIsHotLetsGetOnThat: 'Risparmiare denaro è eccitante. Andiamo su questo ✌️',
  search: 'Cerca',
  seeHowToSave: 'Scopri come risparmiare',
  skipForNow: 'Salta per ora',
  soFarWeSavedYou: 'Finora, ti abbiamo fatto risparmiare',
  sorryForInconvenience: "Ci scusiamo per l'inconveniente, promettiamo che ne varrà la pena.",
  starReviews: '👉 {{count, number}} mila recensioni a 4,7 stelle o più',
  starTheThingsYouNeedInYourLifeForQuickAccess:
    'Scegli le cose di cui hai bisogno nella tua vita per un accesso rapido.',
  stillWannaUnsubscribe: "Vuoi ancora <1>annullare l'iscrizione a tutte le email di Karma</1>?",
  storePromotionsAndOtherMarketingEmails: 'Promozioni del negozio e altre e-mail commerciali',
  storesWorldwide: '👉 più di {{count, number}} mila negozi in tutto il mondo',
  stuffWorthSavingGoesHere: 'Cose che vale la pena salvare vanno qui 👇',
  sureYouWantToDeleteTheseNumberItems: 'Sei sicuro di voler eliminare questi {{value}} articoli?',
  sureYouWantToDeleteThisList: 'Sei sicuro di voler eliminare questo elenco?',
  takeControlOfYourInbox: 'Prendi il controllo della tua casella di posta',
  theMotherOfAllWishLists: 'La madre di tutte le <1>liste dei desideri<1/>',
  thisFieldIsRequired: 'Questo campo è obbligatorio',
  toCashOutRewards: 'Per riscuotere i premi, devi raggiungere un minimo di $ {{minimum, number}}.',
  toCashOutYouMustReach: 'Per riscuotere i premi, devi raggiungere un minimo di {{currency}} {{value, number}} $.',
  tryAgain: 'Riprova',
  trySearchingForSomethingElse: "Prova a cercare qualcos'altro.",
  updatePreferences: 'Aggiorna preferenze',
  wantIt: 'Lo voglio',
  weAreBusySelfImproving: 'Siamo impegnati a migliorare da soli',
  weAreVerySeriousAboutPrivacy: 'Prendiamo molto sul serio la privacy e non venderemo mai i tuoi dati a terzi. Mai.',
  weDontSellYourData: 'Non vendiamo i tuoi dati',
  weGotStuckOnOurWay: 'Siamo rimasti bloccati sulla strada per {{retailer}}!',
  weHitYouUp: 'Ti contatteremo quando troveremo un codice promozionale per questo negozio.',
  weHustleForCoupons: 'Ci affrettiamo per i coupon',
  weUseBankLevelSecurity: 'Utilizziamo la sicurezza a livello bancario',
  wellApplyCodesThatSaveYouMoney: 'Applicheremo codici che ti faranno risparmiare $$$.',
  wellTrackAndScanForCoupons:
    'Tracciamo e cercheremo i coupon in modo che tu non debba farlo. <br/> (ok, non lo faremo noi, ma la nostra IA)',
  wellTrackAndScanForCouponsMobile: 'Tracciamo e cercheremo i coupon in modo che tu non debba farlo tu.',
  wereAVerbNowLol: '(ora siamo un verbo lol)',
  wereGoingShopping: 'Stiamo andando a fare shopping',
  wereHereToMakeItYours: 'Siamo qui per <1>#make_it_yours</1>',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    "Perché annullare l'iscrizione a tutte le nostre e-mail per risparmiare denaro quando puoi disattivare solo quelle che non sono pertinenti?",
  withKarmaYouCanBuy: "Con Karma puoi comprare<br />quello che vuoi e fare felice<br />l'impiegata della banca.",
  withKarmaYourCash: 'Con Karma, i tuoi soldi ti tornano come una sorta di denaro voodoo e magico.',
  yesDisconnect: 'Sì, disconnetti',
  yesIDoThat: 'Sì, lo faccio',
  youHaveToReconnectToWithdraw: 'Dovrai riconnetterti per prelevare il tuo Karma Cash.',
  youNeedToSaveItemsBeforeYouCanShareYourList: 'Devi salvare gli elementi prima di poter condividere il tuo elenco.',
  youReATen:
    'Sei un 10 e paghi con Karma? Acquista online e ottieni il prezzo più basso possibile ogni 👏 singola 👏 volta.',
  youReAboutToMuteNumberItems: 'Stai per disattivare le notifiche per {{value}} articoli',
  yourCashWillComeBackToYou: 'I tuoi soldi ti torneranno come una sorta di denaro voodoo e magico. ',
  yourInformationIsSecured:
    'Le tue informazioni sono protette utilizzando la più recente crittografia a 256 bit per una protezione completa.',
  yourSearchCameUpEmpty: 'La tua ricerca è risultata vuota',
  total: 'Totale',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail:
    "Tieni d'occhio gli aggiornamenti di stato che invieremo a {{email}}.",
  psaDidYouKnow: 'PSA: sai che puoi',
  addWishItemsFromAnyBrand:
    'Aggiungere i "desideri" di qualsiasi marca e fare altro finché non ti consigliamo di acquistarli.',
  capturePurchaseReadyShoppers:
    "Conquistare gli acquirenti pronti all'acquisto mostrando i tuoi coupon più interessanti.",
  gainFullFunnelInsights: "Ricevere informazioni sulle intenzioni d'acquisto per ogni articolo salvato",
  boostCartEngagement: "Aumentare l'engagement della fase carrello con i nostri aggiornamenti sugli articoli.",
  promoteLongTermGrowth: "Promuovere la crescita a lungo termine con campagne su misura nell'ecosistema Karma.",
  reachNewAudiences: 'Raggiungere nuovi segmenti di pubblico con campagne personalizzate per il tuo marchio',
  influencerCollaborations: 'Collaborazioni con influencer',
  teamUpWithTopInfluences: "Collabora con gli influencer più adatti all'identità del tuo marchio.",
  socialPosts: 'Post sui social',
  giveANewFollowing: 'Trova un nuovo seguito grazie a campagne personalizzate e ricche di contenuti.',
  brandFocusedNewsletters: 'Newsletter incentrate sul marchio',
  letYourOffersShine: 'Poni in luce le tue offerte in e-mail molto accurate.',
  driveTrafficWhenYouNeedIt: 'Indirizza il traffico dove necessario grazie ai nostri posizionamenti in homepage',
  dashboardBanners: 'Banner su dashboard',
  flauntYourLatestOfferings: 'Valorizza le tue ultime offerte con immagini accattivanti.',
  dailyFeatured: 'In primo piano ogni giorno',
  highlightDeals: 'Evidenzia le offerte dove conta.',
  dedicatedPopUps: 'Pop-up dedicati',
  activelyServeYourPromos: 'Offri attivamente le tue promozioni in modo che non vadano perse.',
  createAnotherTouchpointForYourBrand: 'Crea un altro punto di contatto per il tuo marchio',
  increaseEngagement:
    "Aumenta l'engagement e i tassi di conversione con notifiche push mirate su dispositivi mobili e web.",
  karmaIsOnlineShoppingButBetter: 'Karma è shopping online, ma migliore.',
  transactionsNumber: '👉 {{count, number}} M+ transazioni',
  partnershipsReview4: 'Ottimo per creare la tua wishlist e risparmiare!',
  partnershipsReviewAuthor4: 'Emily Smith',
  partnershipsReview5:
    'È davvero la migliore estensione che abbia mai scaricato. È il modo migliore per risparmiare e guadagnare denaro allo stesso tempo!',
  partnershipsReviewAuthor5: 'Selena Roblox',
  noNeedToSlideIntoOurDMs:
    'Non è necessario inviarci messaggi diretti (potresti però 😏). Il nostro team è qui per assisterti.',
  supportIssues: 'Problemi di assistenza',
  adsAndPartnerships: 'Annunci e Partnership',
  jobOpportunities: 'Opportunità di lavoro',
  publicRelations: 'Relazioni pubbliche',
  letsChat: 'Parliamo',
  earnings: 'Guadagni',
  allTime: 'Di sempre',
  ordersMayTakeHoursToAppear: 'La visualizzazione degli ordini potrebbe richiedere fino a {{value, number}} ore.',
  youHaventReceivedAnyPayoutsYet: 'Non hai ancora ricevuto alcun pagamento ',
  alerts: 'Avvisi',
  featuresReview1:
    'Ho aggiunto un paio di sneakers alla mia wishlist il giorno dopo ho ricevuto la notifica dello sconto del 50% Come ho fatto ad acquistare prima di conoscervi?',
  featuresReviewAuthor1: "Sandra C.Martinez, recensore sull'App store",
  featuresReview2:
    "Questa è davvero la migliore app per lo shopping intelligente, e ne conosco altre. Mi sento come se stessi imbrogliando un po' il sistema, perché sto risparmiando soldi veri! Pagavo sempre per intero e mi sentivo un'idiota",
  featuresReviewAuthor2: "Gertie D. Ackerman, recensore sull'App store",
  featuresReview3:
    'Stavo acquistando un nuovo laptop con uno sconto già del 25%. Quando ho premuto su "paga con Karma", ho notato che il totale era ancora più basso. Questo succede ogni volta che pago con l\'app',
  featuresReviewAuthor3: "Irene J. Cox, recensore sull'App store",
  downloadNow: 'Scarica ora',
  wannaBeDeskBuddies: 'Vogliamo essere colleghi?',
  seeOpenPositions: 'Visualizza posizioni aperte',
  weLiveAndSwearBy: 'Viviamo per il...',
  goodKarma: 'Buon karma',
  weBelieveThatGoodVibes: 'Crediamo che le "buone vibrazioni" siano uno stile di vita e ad esso ci atteniamo.',
  beingTrue: 'Essere sinceri',
  theresNoUSvsU: "Non c'è un noi vs tu. I nostri tavoli sono sempre rotondi e ti stiamo riservando un posto.",
  passion: 'Passione',
  weHaveThatFireYouKnow:
    'Abbiamo quel fuoco, sai? Operiamo con determinazione, perseveranza e amore per ciò che facciamo.',
  entrepreneurship: 'Imprenditorialità',
  weWantItWeGetIt:
    'Lo vogliamo, ce lo prendiamo Non abbiamo tempo da perdere con meeting infiniti. Lavoriamo per quello che vogliamo.',
  mutuality: 'Mutualità',
  wereMadeUpOfAwesomePeople:
    "Siamo persone fantastiche che sanno che il lavoro di squadra non è sufficiente. Ci prendiamo davvero cura l'uno dell'altro.",
  buildCustomerLoyalty: 'Fidelizza i clienti con cashback istantanei',
  itemSaving: 'Risparmio di articoli',
  itemUpdates: 'Aggiornamenti sugli articoli',
  backInStockAlerts: 'Di nuovo disponibile',
  lowInStockAlerts: 'Disponibilità limitata',
  storePromotions: 'Promozioni del negozio',
  exclusive: 'esclusiva',
  couponCopied: 'Coupon copiato',
  completedDesc:
    'Lo store ha confermato il tuo ordine. Qualsiasi Karma Cash guadagnato può essere prelevato {{days, number}} giorni dalla data di acquisto e solo dopo aver raggiunto il minimo di {{currency}} {{amount, number}}.',
  notQualifiedDesc: 'Potrebbero essere applicate ulteriori restrizioni agli articoli che hai ordinato.',
  pendingDesc:
    'Il negozio ha ricevuto il tuo ordine. Potrebbe essere mostrato in attesa per un massimo di {{days, number}} giorni, a seconda della politica di restituzione del negozio.',
  cancelledDesc: 'Il negozio ci ha informato che il tuo ordine è stato annullato o rimborsato per intero.',
  downloadNowItsFree: 'Scaricalo ora, è gratuito',
  weLiveToMakeOurShoppersHappy: 'Viviamo per rendere felici i nostri clienti',
  moneySavedForYou: 'Soldi risparmiati per te',
  happyShoppers2: 'Acquirenti soddisfatti',
  starReviews2: 'Recensioni a {{value, number}} stelle',
  storesWorldwide2: 'Negozi in tutto il mondo',
  whyYouWill: 'Perché lo',
  it: 'farai',
  multiBrandWishLists:
    'Wishlist multimarchio? Brividi veri. Trova qualcosa che ti piace di qualsiasi marchio, aggiungilo alla tua wishlist Karma e fai le tue cose finché non ti avviseremo in merito.',
  addKarmaToYourMobile: 'SCarica<br /> la nostra app<br /> e porta<br /> Karma in viaggio',
  appStoreReview1:
    'Ho aggiunto una palette di ombretti alla mia lista e il giorno dopo ho ricevuto la notifica dello sconto del 50%. Grazie!!',
  appStoreReview2: 'È pericoloso che il prezzo più basso sia sempre a portata di mano. Ma mi piace anche questo.',
  appStoreReview3:
    "Ho trovato il prezzo più basso per le nuove cuffie mentre aspettavo l'autobus. È davvero troppo facile",
  appStoreReviewBy: "Recensione sull'App store di: {{name}}",
  yourInfoSafeWithUs: 'Le tue informazioni sono al sicuro con noi',
  whyGiveOutYourCCDeets: 'Perché diffondere i dati della tua carta quando puoi conservarli in un unico posto?',
  partyForOne: 'Festa per 1',
  wellNeverSellYourDataToAnyThirdParties:
    'Non venderemo mai i tuoi dati a terzi, punto. Con la sicurezza a livello di banca, i tuoi dati sono al sicuro da occhi indiscreti.',
  buyWithoutATrace: 'Acquista senza lasciare traccia',
  weWontLetAnyoneTrackYourShoppingHabits:
    'Non permetteremo a nessuno di monitorare le tue abitudini di acquisto. Nessuno ti giudica.',
  spotSomethingYouLoveFromAnyBrand:
    'Trova qualcosa che ti piace di qualsiasi marchio, aggiungilo alla tua wishlist Karma e fai le tue cose finché non ti avviseremo in merito.',
  needIt: 'Lo voglio',
  appStoreReviewer: "Recensore sull'App store",
  appStoreReview4:
    'Mi affido moltissimo a quest\'app per tenere traccia di tutti i miei articoli "desiderati" in un unico posto e trovare il miglior prezzo possibile.',
  appStoreReview5: "Quest'app mi ha aiutato a risparmiare davvero tanti soldi.",
  wishlist: 'Wishlist',
  getTheLowestPriceAtAllYourFavoriteBrands: 'Trova i prezzi più bassi per tutti i tuoi marchi preferiti.',
  getTheAppForFree: "Scarica l'app gratuitamente",
  addKarmaSpaced:
    "Aggiungi Karma <br />al tuo desktop<br /> in modo da poter fare acquisti<br /> durante l'orario di lavoro.",
  spotSomethingYouLoveFromAnywhere:
    'Trova qualsiasi cosa ti piaccia, aggiungila alla tua lista Karma e fai le tue cose finché non ti avviseremo in merito.',
  getCouponsCashbackWithPeriod:
    'Ricevi coupon, cashback e avvisi di riduzione dei prezzi.<br />Paga sempre il prezzo più basso.',
  getCouponsCashbackWithAnd:
    'Ricevi coupon, cashback, avvisi di riduzione dei prezzi<br /> e paga sempre il prezzo più basso.',
  clickTheLoginLinkFromYourPhone: "Clicca sul link di accesso dal tuo telefono per accedere all'app Karma.",
  getItOn: 'Disponibile su',
  downloadOnThe: 'Scarica su',
  likeShoppingFromYourComputer: 'Ti piace fare acquisti dal tuo computer?',
  getTheKarmaExtensionToShopEvenBetter: "Scarica l'estensione Karma per acquistare meglio.",
  headerMobileLogin: 'Karma | Installa',
  noFomoHereMobile:
    'Qui non rischi di perdere nulla. Ti avviseremo quando i tuoi "desideri" diminuiranno di prezzo o torneranno disponibili.',
  withKarmaYourCashMobile: 'Con Karma, il denaro tornerà a te come una sorta di rito voodoo e magico.',
  spotSomethigYouLoveMobile:
    'Trova qualsiasi cosa ti piaccia, aggiungila alla tua lista Karma e fai le tue cose finché non ti avviseremo in merito.',
  wellTrackAndScanForCouponsMobile2:
    'Moniteremo e cercheremo i coupon in modo che non debba farlo tu. (ok, non lo faremo noi, ma la nostra IA)',
  weHustle: 'Alla pazza ricerca',
  forCoupons: 'di <1>couon</1>',
  theMotherOfAll: 'La madre di tutte',
  wishlists2: 'le wishlist',

  // NEW PHRASES
  moneySavedForUsers: 'Money saved for users',
  whyYouLoveMerchantsTitleDesktop3: '<1>One-swipe<1/> checkout is yours',
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  whyYouLoveMerchantsSubtitle3: 'Pay with Karma works with all retail platforms, with zero\nintegration and no fees.',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  postCode: 'Postcode',
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by finding the best benefits and rewards at checkout',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUpsWhileIShop: 'Too many pop-ups while I shop',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOn: 'Enable Karma on Safari',
  toAccessOurCouponScanner: 'to access our coupon scanner',
  dontSeeReview: 'I don’t see a “Review” button',
  iosOnboardingFlowYouNeedToUseSafari: 'You need to be in Safari to use the Karma extension',
  iosOnboardingFlowCopyTheLink: 'Copy the link below and paste it in Safari to continue.',
  iosOnboardingCopyLink: 'Copy link',
  iosOnboardingLinkCopied: 'Link copied',
  tapExtensionThenSelect: 'extension, then <1>select both middle options</1>',
  clickInTheAddressBar: 'Click<1></1>or',
  activate: 'Activate',
  grantAccess: 'Grant access',
  sweOnboardingStep1_1_1: 'Tap <1></1> or',
  sweOnboardingStep1_1_2: 'in the address bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the address bar',
  sweOnboardingStep1_2: 'Select <1>“Manage Extensions”<1/>',
  sweOnboardingStep1_3: 'Turn on <1>“Karma”</1>',
  sweOnboardingStep2_1_old: 'Tap the <1>A</1><2>A</2> in the address bar again',
  sweOnboardingStep2_2_old:
    'Tap the Karma extension <1></1>,<3></3> and select <5>“Always Allow...”</5>, then <7></7><8>“Always Allow on Every Website”</8>',
  sweOnboardingStep2_3_old: 'Select “Always allow”',
  sweOnboardingStep2_4_old_1: 'Select “Always allow”',
  sweOnboardingStep2_4_old_2: '“On every Site”',

  sweOnboardingStep2_5_old_1: 'Go back to the',
  sweOnboardingStep2_5_old_2: 'Karma app',
  sweOnboardingStep2_1: 'Tap <1>“Review”</1> in the top right corner, then <3>select both middle options</3>',
  shopSmarter: 'Shop better with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nbetter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nbetter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop better by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping better from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
  theKButtonIsInMyWay: 'The K button is in my way',
  itWasntHelpful: 'It wasn’t helpful',
  reinstallTheExtension: 'Reinstall the extension',
  tooManyCouponPopupsAtCheckout: 'Too many coupon pop-ups at checkout',
  didYouKnowYouCanHideTheKButtonAndDisablePopupsFromYourSettings:
    'Did you know you can <1>hide</1> the K button and <3>disable</3> pop-ups from your settings?',
  cookies:
    'If you accept cookies, we’ll use them to improve and customize your experience and enable our partners to show you personalized ads when you visit other sites. <2>Manage cookies and learn more</2>',
  decline: 'Decline',
  confirmYourCookieSettings: 'Confirm your cookie settings',
  youControlHowWeUseCookies:
    'You get to control how we use cookies on each device and browser you use. These settings will apply to your current device when you use {{browser}}.',
  whatAreCookies: 'What are cookies?',
  cookiesAndTrackingTechnologies:
    'Cookies and tracking technologies are small text files stored on your device when you use a web browser. Some cookies are essential for you to use our site, while other cookies collect data about your browsing habits. We use this data to give you the best experience.',
  marketing: 'Marketing',
  weUseMarketingCookies: "We use marketing cookies to deliver ads we think you'll like.",
  marketingCookiesLet: 'For example, marketing cookies let us show you personalized ads based on your interests.',
  performance: 'Performance',
  weUsePerformanceCookies: 'We use performance cookies to understand how you interact with our site.',
  performanceCookiesHelp:
    'For example, performance cookies help us learn which parts are the most popular and which parts we could improve for you.',
  functional: 'Functional',
  weUseFunctionalCookies: 'We use functional cookies to customize your experience.',
  functionalCookiesLet:
    'For example, functional cookies let us remember your preferences like language, country or region, and text sizes.',
  essential: 'Essential',
  weUseEssentialCookies: 'We use essential cookies to make our site work for you.',
  essentialCookiesLet:
    'For example, essential cookies let you securely sign in and browse our site. These cookies help us keep your account safe and prevent fraud.',
  youCanReadMoreAboutCookies: 'You can read more about how we use cookies in our <2>Cookie Statement</2>.',
  confirmCookieSettings: 'Confirm Cookie Settings',
  noMinimumOrderValue: 'No minimum order value',
  fromToDays: '{{value, number}} to {{value2, number}} days',
  headerLogin: 'Karma | Login',
  headerOnboarding: 'Karma | Onboarding',
  yourDataHasBeenRemoved: 'Your data has been successfully removed.',
  confirmationCode: 'Confirmation code: {{code}}',
  startOnlineShopping: 'Start online shopping to see Karma in action',
  trendingOffers: 'Trending Offers',
  useCode: 'Use code: {{code}}',
  getDeal: 'Get Deal',
  buyNow: 'Buy now',
  trendingCoupons: 'Trending Coupons',
  searchResultsFor: 'Search Results for "{{search}}"',
  trendingItems: 'Trending Items',
  curateSmartWishlists: 'Curate Smart wishlists',
  karmaWillLetYouKnow: 'Karma will let you know when the items you want go on sale or are back in stock',
  saveEarnAtCheckout: 'Save & Earn at checkout',
  weApplyBestCoupon:
    'We’ll automatically apply the best coupon codes on the web at checkout & reward for your purchase on 1000’s of stores',
  welcomeTo: 'Welcome to',
  youAreOneStepCloser:
    'You’re one step closer to saving money\nand shopping smarter.\nGo to your favorite store and we’ll see you there!',
  startBrowsing: 'Start browsing',
  scanToGetApp: 'Scan to get the app',
  unlockYourCreditCardsBenefits: 'Unlock your credit cards benefits',
  easilyCompareBetweenCreditCards:
    'Easily compare between the credit cards rewards to choose the best one to use at checkout',
};
