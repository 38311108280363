import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const PuzzleIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M18.5 3H1.5L1 4V9.5H3L4.5 10.5V12.5L4 13.5L2.5 14.5L1 15V20.5H8.5H18.5V15L19 14H21L22 13.5L23 12L22.5 10.5L22 9.5L20 9L18.5 9.5V8V3Z"
        fill={getColor(secondaryColor)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0566406 3.13293C0.0566406 2.66349 0.437199 2.28293 0.906641 2.28293H18.6408C19.1102 2.28293 19.4908 2.66349 19.4908 3.13293V8.22409C19.7629 8.15637 20.0475 8.12041 20.3403 8.12041C22.2789 8.12041 23.8504 9.69194 23.8504 11.6305C23.8504 13.5691 22.2789 15.1406 20.3403 15.1406C20.0475 15.1406 19.7629 15.1047 19.4908 15.037V20.867C19.4908 21.3365 19.1102 21.717 18.6408 21.717H0.906641C0.437199 21.717 0.0566406 21.3365 0.0566406 20.867V14.4189C0.0566406 14.1293 0.204037 13.8597 0.44777 13.7034C0.691503 13.5471 0.998044 13.5256 1.2612 13.6464C1.48964 13.7512 1.74429 13.8101 2.01502 13.8101C3.01472 13.8101 3.82514 12.9997 3.82514 12C3.82514 11.0003 3.01472 10.1899 2.01502 10.1899C1.74429 10.1899 1.48963 10.2487 1.2612 10.3536C0.998044 10.4744 0.691503 10.4529 0.44777 10.2966C0.204037 10.1403 0.0566406 9.87062 0.0566406 9.58107V3.13293ZM1.75664 3.98293V8.49927C1.84201 8.49304 1.92818 8.48987 2.01502 8.48987C3.95361 8.48987 5.52514 10.0614 5.52514 12C5.52514 13.9386 3.95361 15.5101 2.01502 15.5101C1.92818 15.5101 1.84201 15.5069 1.75664 15.5007V20.017H17.7908V13.677C17.7908 13.3474 17.9813 13.0475 18.2798 12.9075C18.5782 12.7675 18.9307 12.8127 19.1842 13.0234C19.4981 13.2844 19.8998 13.4406 20.3403 13.4406C21.34 13.4406 22.1504 12.6302 22.1504 11.6305C22.1504 10.6308 21.34 9.82041 20.3403 9.82041C19.8998 9.82041 19.4981 9.97665 19.1842 10.2376C18.9307 10.4484 18.5782 10.4935 18.2798 10.3536C17.9813 10.2136 17.7908 9.91365 17.7908 9.58401V3.98293H1.75664Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PuzzleIconFilled;
