import { call, put } from 'redux-saga/effects';
import { fetchPromoSearchCouponsApi } from '../api';
import { clearPromoSearchCoupons, setIsFetching, setPromoSearchCoupons } from '../actions';

const PER_PAGE = 20;

function* fetchPromoSearchCouponsWorker({ payload }) {
  const { search, page } = payload;

  try {
    if (page === 1) {
      yield put(clearPromoSearchCoupons());
    } else {
      yield put(setIsFetching(true));
    }

    const response = yield call(fetchPromoSearchCouponsApi, { search, page, perPage: PER_PAGE });

    yield put(
      setPromoSearchCoupons({
        items: response.data.offers || [],
        page,
        isLastPage: response.data.offers.length < PER_PAGE,
      }),
    );
  } catch (error) {
    yield put(setPromoSearchCoupons({ items: [], page, isLastPage: true }));
    console.error(error);
  }
}

export default fetchPromoSearchCouponsWorker;
